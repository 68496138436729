
//== Font

$font-family:           Microsoft YaHei,sans-serif;
$font-size:             10px;
$font-height:           1.5;

@font-face {
  font-family: SST W20 Light;
  src: url(../resources/fonts/SST-W20-Light.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Light.eot?#iefix) format("eot"),url(../resources/fonts/SST-W20-Light.woff2) format("woff2"),url(../resources/fonts/SST-W20-Light.woff) format("woff"),url(../resources/fonts/SST-W20-Light.ttf) format("truetype"),url(../resources/fonts/SST-W20-Light.svg#SST-W20-Light) format("svg")
}

@font-face {
  font-family: SST W20 Roman;
  src: url(../resources/fonts/SST-W20-Roman.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Roman.eot?#iefix) format("eot"),url(../resources/fonts/SST-W20-Roman.woff2) format("woff2"),url(../resources/fonts/SST-W20-Roman.woff) format("woff"),url(../resources/fonts/SST-W20-Roman.ttf) format("truetype"),url(../resources/fonts/SST-W20-Roman.svg#SST-W20-Roman) format("svg")
}

@font-face {
  font-family: SST W20 Medium;
  src: url(../resources/fonts/SST-W20-Medium.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Medium.eot?#iefix) format("eot"),url(../resources/fonts/SST-W20-Medium.woff2) format("woff2"),url(../resources/fonts/SST-W20-Medium.woff) format("woff"),url(../resources/fonts/SST-W20-Medium.ttf) format("truetype"),url(../resources/fonts/SST-W20-Medium.svg#SST-W20-Medium) format("svg")
}

@font-face {
  font-family: SST W20 Bold;
  src: url(../resources/fonts/SST-W20-Bold.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Bold.eot?#iefix) format("eot"),url(../resources/fonts/SST-W20-Bold.woff2) format("woff2"),url(../resources/fonts/SST-W20-Bold.woff) format("woff"),url(../resources/fonts/SST-W20-Bold.ttf) format("truetype"),url(../resources/fonts/SST-W20-Bold.svg#SST-W20-Bold) format("svg")
}

@font-face {
  font-family: sonyngp;
  src: url(../resources/fonts/sony-ngp.eot);
  src: url(../resources/fonts/sony-ngp.eot?#iefix) format("embedded-opentype"),url(../resources/fonts/sony-ngp.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: FontAwesome;
  src: url(../resources/fonts/fontawesome-webfont.eot);
  src: url(../resources/fonts/fontawesome-webfont.eot?#iefix) format("embedded-opentype"),url(../resources/fonts/fontawesome-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

//== Color

// Normal mode
$color-foreground:      #202020;
$color-background:      #FFF;
$color-link:            #2020E0;

// Dark mode
$color-foreground-dark: invert($color-foreground);
$color-background-dark: invert($color-background);
$color-link-dark:       invert($color-link);
