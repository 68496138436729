//== Base

html,
body {
    margin: 0;
    font-size:   $font-size;
    font-family: $font-family;
    line-height: $font-height;
    color:       $color-foreground;
    background:  $color-background;

    @media (prefers-color-scheme: dark) {
        color:       $color-foreground-dark;
        background:  $color-background-dark;
    }

   /* &.nav-show{
        background-color:rgba(0,0,0,.45);
    }*/
    -webkit-transition: background-color .5s ease;
    transition: background-color .5s ease;
}


a {
    color: $color-link;
    text-decoration: none;
    @media (prefers-color-scheme: dark) {
        color: $color-link-dark;
    }
}

*, :after, :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
    padding:0;
    border: none;
    background: none;
}
li{
    list-style: none;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: inherit;
    font-family: Microsoft YaHei,sans-serif;
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-weight: 500;
}
p{
    margin:0;
    color: inherit;
    font-family: Microsoft YaHei,sans-serif;
}

body, div>span, h3>span, label>span, p {
    font-family: Microsoft YaHei,sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

#accessory,.gallery,#introduction,#property,#specification,#relatedproduct{
    &.cq-Editable-dom{
        padding-top:2rem;
        padding-bottom:2rem;
    }
}

.bottom-more{
    padding-bottom: 20px;
}
.top-more{
    padding-top:20px;
}

.bg-gray{
    background-color: #f4f7fa;
}
.bg-deep-green{
    background-color: #2c3e50;
}
.bg-car-gray{
    background-color: #f2f5f9;
}

.text-align-left{
    text-align: left;
}

.text-align-center{
    text-align: center;
}

.text-align-right{
    text-align: right;
}

.font-color-black{
    color:#333;
}

.font-color-white{
    color:#fff;
}

body{
    @media (prefers-color-scheme: dark) {
        background-color: #fff;
        color:#000;
    }
}

.pc-only{
    display: block  !important;
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}
.mobile-only{
    display: none !important ;
    @media screen and (max-width: 768px) {
        display: block !important;
    }
}
