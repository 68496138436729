.root {
  overflow: hidden;
}

.breadcrumb,.solutionBreadcrumb {
  background-color: #f6f6f6;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  box-sizing: border-box;

  .breadcrumb-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 116.2rem !important
    }

    ol {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      /*height: 4.56rem;*/
      @media screen and (max-width: 1024px) {
        // padding-left: 3rem;
      }

      li {
        padding: 1rem 1.5rem 1rem 1.5rem;
        background-color: inherit;
        font-size: 1.3rem;
        position: relative;
        display: inline-block;

        a {
          color: #555;
          text-decoration: none;
          font-weight: 700;
          font-size: 1.4rem;

          &:hover {
            color: #00587d;
          }
        }

        &:not(:last-child) {
          &::before {
            background-color: rgba(90, 91, 93, .13);
            color: transparent;
            content: "";
            display: block !important;
            height: 1rem;
            padding: 0;
            position: absolute;
            right: -0.5rem;
            top: 50%;
            -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
            -webkit-transform-origin: center top;
            transform-origin: center top;
            width: .1rem;
          }

          &::after {
            background-color: rgba(90, 91, 93, .13);
            color: transparent;
            content: "";
            display: block !important;
            height: 1rem;
            padding: 0;
            position: absolute;
            right: -0.5rem;
            top: 50%;
            -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
            -webkit-transform-origin: center top;
            transform-origin: center top;
            width: .1rem;
            -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
          }
        }

        &:not(:last-child), &:not(:first-child) {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &:last-child, &:first-child {
          @media screen and (max-width: 768px) {
            display: inline-block;
          }
        }

        &:last-child{
          vertical-align: bottom;
          a{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            max-width: 38rem;
            @media screen and (max-width: 768px) {
              max-width: 24rem;
            }
          }
        }
      }
    }
  }
}

.banner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  box-sizing: border-box;

  .banner-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    padding: 4rem 1.5rem 4rem 1.5rem;
    overflow: hidden;

    .banner-title {
      width: 50%;
      float: left;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      h1 {
        font-size: 3.2rem;
        line-height: 3.6rem;
        display: block;
        margin-bottom: 1.5rem;
        span{
          font-size: 1.6rem;
          margin-left: .5rem;
          top: -.7rem;
          background-color: #2c3e50;
          border-radius: 0;
          font-weight: 700;
          padding: 3px 5px;
          position: relative;
          text-transform: uppercase;
          display: inline;
          line-height: 1;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          vertical-align: baseline;
        }
      }

      p {
        font-size: 2.8rem;
        line-height: 3.2rem;
        display: block;
        margin-bottom: 1.5rem;
      }

      a {
        background-color: #ffd200;
        border: 2px solid #ffd200;
        color: #2c3e50;
        margin-right: 1.6rem;
        margin-bottom: 2.5rem;
        min-width: 12rem;
        padding: 1.2rem 3rem;
        text-align: center;
        font-size: 1.6rem;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        display: inline-block;
        box-sizing: border-box;
        &.white{
          border-color: #2c3e50!important;
          color: #2c3e50!important;
          background-color: #fff!important;
          &:hover{
            color: #fff!important;
            background-color: #2c3e50!important;
          }
        }

        &:hover {
          background-color: #2c3e50;
          border: .2rem solid #ffd200;
          color: #ffd200;
        }
      }
    }

    .banner-image {
      width: 50%;
      float: left;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
  }
}

.navigator {
  padding-top: 2rem;
  background-color: #2c3e50;
  border-bottom: 2px solid #f3f6fa;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  /*@media screen and (max-width: 992px) {
    display: none;
  }*/

  &.fixed {
    position: fixed;
    z-index: 52;
    top: 0;
    display: block!important;
    .navigator-container {
      overflow: initial !important;
    }
  }

  .navigator-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    overflow: hidden;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
    min-height: 4rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      min-height:unset;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      min-height:unset;
    }
    .navigator-title {
      width: 58.33333%;
      float: left;
      box-sizing: border-box;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      @media screen and (max-width: 992px) {
        padding-left: 3rem;
        padding-right: 3rem;
        margin-top:2rem;
        width: 100%;
        display: none;
      }
      h2 {
        font-size: 2.4rem;
      }
    }

    .navigator-button {
      max-height: 0px;
      opacity: 0;
      -webkit-transition-delay: .25s;
      transition-delay: .25s;
      padding-top: .7rem;
      width: 41.66667%;
      float: left;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      -webkit-transition: max-height .5s ease, opacity .5s ease, margin .5s ease;
      transition: max-height .5s ease, opacity .5s ease, margin .5s ease;
      @media screen and (max-width: 992px) {
        display: none;
      }
      a {
        display: inline-block;
        border: .1rem solid #adadad;
        border-radius: 0;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        font-size: 1.6rem;
        margin-right: 1.6rem;
        margin-bottom: 2.5rem;
        min-width: 12rem;
        padding: 1.2rem 3rem;
        text-align: center;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        color: #fff;
        box-sizing: border-box;
        &.yellow{
          background-color: #ffd200!important;
          border: 2px solid #ffd200!important;
          color: #2c3e50!important;
          &:hover{
            background-color: #2c3e50!important;
            color: #ffd200!important;
          }
        }
        &:hover {
          background-color: #f3f6fb;
          color: #2c3e50;
        }
      }
    }

    .navigator-tabs {
      width: 100%;
      box-sizing: border-box;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      float: left;
      @media screen and (max-width: 992px) {
        padding-left: 3rem;
        padding-right: 3rem;
        display: none;
      }
      ul {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        li {
          float: left;
          margin-bottom: -1px;
          max-width: 33.33333333333%;
          padding-right: 25px;
          width: auto;
          min-height: 19px;
          text-align: left;
          height: 100%;
          display: inline-block;
          @media screen and (max-width: 992px) {
            max-width: unset;
            width: 100%;
          }
          a {
            border-bottom: 0;
            color: #fff;
            display: block;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            padding: 0;
            padding-bottom: 2rem;
            position: relative;
            text-align: left;
            margin-right: 1px;
            border: none;
            height: 100%;
            font-weight: 700;
            @media screen and (max-width: 992px) {
              text-align: center;
              padding-top:2rem;
              border-top: 1px solid hsla(0,0%,100%,.5);
            }
            &.hover {
              color: #81dcf7 !important;
              border-bottom: none !important;
              background-color: transparent !important;
              cursor: pointer;
            }

            &.active {
              color: #81dcf7 !important;
              border-bottom: none !important;
              background-color: transparent !important;
              cursor: pointer;
              @media screen and (max-width: 992px) {
                border-top: none;
              }
              &::before {
                background-color: transparent;
                border-color: #2c3e50 transparent transparent;
                border-style: solid;
                border-width: 1.5rem 1.25rem 0;
                bottom: -1.3rem;
                content: "";
                display: block;
                height: 0;
                left: 1rem;
                position: absolute;
                -webkit-transform: rotate(1turn);
                transform: rotate(1turn);
                width: 0;
                opacity: 1;
                @media screen and (max-width: 992px) {
                  display: none;
                }
              }
            }

            &::before {
              bottom: 0;
              content: "";
              display: block;
              height: .4rem;
              left: 0;
              opacity: 0;
              position: absolute;
              -webkit-transition: opacity .5s ease;
              transition: opacity .5s ease;
              width: 100%;
              backface-visibility: hidden;
              -webkit-backface-visibility: hidden;
              @media screen and (max-width: 992px) {
                display: none;
              }
            }
          }
        }
      }
    }
    .navigator-mobile-header{
      display: none;
      padding:0 0 2rem 0;
      @media screen and (max-width: 992px) {
        display: block;
      }
      a{
        display: block;
        position: relative;
        width: 3rem;
        min-height: 1.8rem;
        float: left;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        overflow: hidden;
        &.active{
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
        span{
          width: 30px;
          height: 2px;
          display: block;
          position: absolute;
          background-color: #fff;
          top: 0;
          left: 0;
          &:nth-of-type(2){
            top: 50%;
            margin-top: -.1rem;
          }
          &:nth-of-type(3){
            bottom: 0;
            top: auto;
          }
        }
      }
    }
  }
}

.gallery-root {
  background-color: #f3f6fb;
  overflow: hidden;
  padding: 2.5rem 2.5rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    padding: 6rem 2.5rem;
  }
  @media screen and (min-width: 992px) {
    padding: 8rem 1.5rem;
  }

  .gallery-title, .gallery-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
  }

  .gallery-title {
    box-sizing: border-box;

    h2 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  .gallery-container {
    padding-top: 3.5rem;
    position: relative;

    .swiper {
      width: 100%;
      padding-bottom: 5rem;

      .swiper-wrapper {
        width: 100%;

        .swiper-slide {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          width: 100%;
          box-sizing: border-box;

          .gallery-image {
            background-size: contain;
            background-repeat: no-repeat;
            overflow-y: hidden;
            padding-bottom: 56.25%;
          }
        }
      }
    }

    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      height: 60px;
      left: -20px;
      margin-top: -30px;
      opacity: .75;
      padding: 15px 5px;
      text-align: center;
      top: 50%;
      -webkit-transition: opacity .5s ease;
      transition: opacity .5s ease;
      width: 35px;
      z-index: 1;
      cursor: pointer;
      box-sizing: border-box;

      &::before {
        padding: 15px 5px;
        content: "x";
        text-indent: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-family: sonyngp !important;
        line-height: 1em;
        font-size: 30px;
        content: "x";
        text-align: center;
        text-indent: 0;
        color: #0a5e9d;
      }

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    .swiper-button-next {
      left: auto;
      right: -20px;

      &::before {
        content: "y";
        text-indent: -1px;
      }
    }
  }
}

.introduction-root {
  padding: 8rem 1.5rem;

  .intro-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    overflow: hidden;

    .intro-title {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      width: 16.66667%;
      float: left;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      h2 {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 3.2rem;
      }
    }

    .intro-content-container {
      width: 83.33333%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      float: left;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      h3 {
        font-weight: 700;
        margin-bottom: .8rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      .intro-content {
        margin-bottom: 2.5rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}

.property-root {
  padding: 8rem 1.5rem;
  background-color: #f3f6fb;
  width: 100%;
  box-sizing: border-box;

  .property-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    overflow: hidden;

    .property-title {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      width: 16.66667%;
      float: left;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      h2 {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 3.2rem;
      }
    }

    .property-content-container {
      width: 83.33333%;
      padding: 0;
      box-sizing: border-box;
      float: left;
      position: relative;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      .property-item {
        position: absolute;
        float: left;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: 50%;
        box-sizing: border-box;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        @media screen and (max-width: 992px) {
          width: 100% !important;
        }

        .property-wrapper {
          background-color: #fff;
          padding: 2rem;
          margin-bottom: 2rem;

          h3 {
            font-weight: bold;
            margin-bottom: 1rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
          }

          .property-content {
            margin-bottom: 2.5rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        }
      }
    }
  }
}
.specDescription{
  margin: -6rem auto 0;
  padding-bottom: 1rem;
  width: 76%;
  word-break: break-all;
  @media screen and (min-width: 480px) {
    width: 82% !important;
  }
  @media screen and (min-width: 768px) {
    width: 69rem !important;
  }
  @media screen and (min-width: 992px) {
    width: 91rem !important;
    padding-left: 15.4rem;
  }
  @media screen and (min-width: 1200px) {
    width: 111rem !important;
    padding-left: 19rem;
  }
}
.specification-root {
  padding: 4.5rem 1.5rem 8rem 1.5rem;
  width: 100%;
  box-sizing: border-box;

  .spec-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    overflow: hidden;

    .spec-title {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      width: 16.66667%;
      float: left;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      h2 {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 3.2rem;
        margin-top: 6.9rem;
      }
    }

    .spec-content-container {
      width: 83.33333%;
      box-sizing: border-box;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      float: left;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      .spec-button {
        text-align: right;
        @media screen and (max-width: 768px) {
          text-align: left;
        }
        @media screen and (max-width: 480px) {
          display: none;
        }

        button {
          background-color: #f3f6fa;
          border: 0;
          color: #2c3e50;
          display: inline-block;
          font-size: 1.6rem;
          line-height: 1.6rem;
          padding: 1.4rem 6rem 1.4rem 2.5rem;
          position: relative;
          margin-right: 1.6rem;
          margin-bottom: 2.5rem;
          min-width: 12rem;
          text-align: center;
          -webkit-transition: all .25s ease;
          transition: all .25s ease;
          box-shadow: none !important;
          border-radius: 0;
          font-weight: 400;
          white-space: nowrap;
          vertical-align: middle;
          -ms-touch-action: manipulation;
          touch-action: manipulation;
          cursor: pointer;

          &:hover {
            background-color: #e1f0f5;
          }

          &:focus {
            background-color: #e1f0f5;
            outline: .5rem auto -webkit-focus-ring-color;
            outline-offset: -.2rem;
          }

          &.btn-expand-all {
            &::after {
              color: #2c3e50;
              content: "z";
              font-family: sonyngp !important;
              font-size: 3.2rem;
              height: 1.4rem;
              line-height: .8rem;
              margin-top: -.8rem;
              position: absolute;
              right: 1.5rem;
              text-align: center;
              text-indent: -.5rem;
              top: 50%;
              -webkit-transition: all .25s ease;
              transition: all .25s ease;
              width: 2.3rem;
              box-sizing: border-box;
            }
          }

          &.btn-collapse-all {
            &::after {
              color: #2c3e50;
              content: "w";
              font-family: sonyngp !important;
              font-size: 3.2rem;
              height: 1.4rem;
              line-height: 1.6rem;
              margin-top: -.8rem;
              position: absolute;
              right: 1.5rem;
              text-align: center;
              text-indent: -.4rem;
              top: 50%;
              -webkit-transition: all .25s ease;
              transition: all .25s ease;
              width: 2.3rem;
              box-sizing: border-box;
            }
          }
        }
      }

      .spec-group {
        margin: 0;
        border-bottom: .4rem solid #fff;

        & > span {
          background-color: #f3f6fb;
          border: 0;
          cursor: pointer;
          display: block;
          font-size: 1.8rem;
          padding: 1.5rem 4.3rem 1.5rem 1.9rem;
          position: relative;
          -webkit-transition: background-color .4s ease;
          transition: background-color .4s ease;
          box-sizing: border-box;
          font-weight: 700;

          &:hover {
            background-color: #e1f0f5;
          }

          &::after {
            color: #2c3e50;
            content: "w";
            display: block;
            font-family: sonyngp !important;
            font-size: 3.2rem;
            height: 1.4rem;
            line-height: .8rem;
            margin-top: -.7rem;
            opacity: .5;
            position: absolute;
            right: 1.5rem;
            text-align: center;
            text-decoration: none;
            text-indent: -.5rem;
            top: 50%;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            -webkit-transition: opacity .4s ease, -webkit-transform .4s ease;
            transition: opacity .4s ease, -webkit-transform .4s ease;
            transition: transform .4s ease, opacity .4s ease;
            transition: transform .4s ease, opacity .4s ease, -webkit-transform .4s ease;
            width: 2.3rem;
            font-weight: 500;
          }

          &.is-open {
            &::after {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
          }
        }

        .spec-item-container {
          height: 0;
          transition: height 400ms linear 0s;
          overflow: hidden;
          padding: 0;

          &.open {
            padding: 1.5rem .7rem;
          }

          .spec-item {
            margin-bottom: 15px;
            width: 100%;
            overflow: hidden;

            .spec-key {
              width: 33.33333%;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              box-sizing: border-box;
              font-weight: 700;
              float: left;
              min-height: .1rem;
            }

            .spec-value {
              width: 66.66667%;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              box-sizing: border-box;
              word-wrap: break-word;
              float: left;
            }

            .spec-bottom {
              width: 100%;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              box-sizing: border-box;
              float: left;

              span {
                background-color: #bebebe;
                border: 0;
                display: block;
                height: .1rem;
                margin: .8rem 0;
              }
            }

            &:last-child {
              .spec-bottom {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.accessory-root {
  padding: 8rem 1.5rem 3rem 1.5rem;
  width: 100%;
  box-sizing: border-box;

  .accy-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    overflow: hidden;

    .accy-title {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      width: 25%;
      float: left;
      box-sizing: border-box;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      h2 {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 3.2rem;
      }
    }

    .accy-content-container {
      width: 75%;
      float: left;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }

      .accy-item {
        margin-bottom: 1rem;
        padding-left: .1rem;
        padding-right: .1rem;
        display: block;
        width: 100%;
        position: relative;
        /*float: left;*/
        box-sizing: border-box;

        .accy-item-comment {
          background: rgba(0,0,0,0.6);
          color: #fff;
          position: absolute;
          left: 0;
          top: 0;
          padding: 0.5rem 1rem;;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          display: none;
          font-size: 1.4rem;
          overflow-y: scroll;
        }
        /* 自定义滚动条样式 */
        .accy-item-comment::-webkit-scrollbar {
          width: 4px;
        }

        .accy-item-comment::-webkit-scrollbar-track {
          background: rgba(0,0,0,0);;
        }

        .accy-item-comment::-webkit-scrollbar-thumb {
          background: #000;
        }

        .accy-item-comment::-webkit-scrollbar-thumb:hover {
          background: #000;
        }
        .accy-item-comment-icon {
          background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/img-remarks.png");
          background-repeat: no-repeat;
          width: 5.6rem;
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          height: 2.6rem;
        }

        @media screen and (min-width: 425px) {
          width: 50% !important;
        }
        @media screen and (min-width: 768px) {
          width: 100% !important;
        }
        @media screen and (min-width: 992px) {
          width: 25% !important;
        }

        &:hover {
          .accy-image {
            border-color: #7ac3d3;
          }
        }

        .accy-image {
          background-color: #fff;
          padding: 2rem 0;
          border: .1rem solid #e1f0f5;
          img {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        }

        h4 {
          padding: 1.2rem 2rem .5rem 0;
          color: #2c3e50;
          text-align: left;
          font-size: 2rem;
          line-height: 2.6rem;
          display: block;
        }

        p {
          color: #4a4a4a;
          font-size: 1.2rem;
          padding: 0 5rem 0 0;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 1rem;
          display: block;
        }
      }
    }

    .accy-more {
      margin-left: 25%;
      width: 75%;
      float: left;
      box-sizing: border-box;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      a {
        display: inline-block;
        font-size: 1.6rem;
        margin-top: 4rem;
        padding-right: 5rem;
        position: relative;
        color: #0a5e9d;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
          color: #3c4c74;
        }

        .icon-down-arrows {
          display: block;
          font-size: 3.2rem;
          height: 3.2rem;
          line-height: 3.2rem;
          margin-top: -1.9rem;
          position: absolute;
          right: 0;
          top: 50%;
          width: 3.2rem;

          &::before {
            content: "z";
            font-family: sonyngp !important;
            font-style: normal !important;
            font-weight: 400 !important;
            -webkit-font-feature-settings: normal !important;
            font-feature-settings: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.relatedproduct {
  padding: 8rem 1.5rem;
  background-color: #f3f6fb;
  width: 100%;
  box-sizing: border-box;

  .related-product-title {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }

    h2 {
      margin-bottom: 4rem;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  .related-product-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }

    .related-product-content {
      @media screen and (max-width: 992px) {
        padding-bottom: 5rem;
      }

      .swiper-pagination {
        display: none;
        @media screen and (max-width: 992px) {
          display: block;
        }
      }
    }

    .related-product-item {
      padding-left: .2rem;
      padding-right: .2rem;
      width: 100%;
      box-sizing: border-box;
      display: block;
      float: left;
      cursor: pointer;
      @media screen and (min-width: 425px) {
        width: 50%;
      }
      @media screen and (min-width: 480px) {
        width: 33.3333% !important;
      }
      @media screen and (min-width: 768px) {
        width: 25% !important;
      }
      @media screen and (min-width: 1024px) {
        width: 20% !important;
      }

      &:hover {
        .img {
          border-color: #7ac3d3;
        }
      }

      .related-product-image {
        margin-bottom: 1.5rem;
        width: 100%;

        img {
          width: 100%;
          border: .1rem solid #e1f0f5;
        }
      }

      h3 {
        color: #2c3e50;
        margin-bottom: .5rem;
        max-width: 95%;
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: .5rem;
      }

      p {
        color: #282c36;
        line-height: 2.4rem;
        max-width: 95%;
        word-wrap: break-word;
        margin-bottom: 0;
        font-weight: 700;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      height: 60px;
      left: -55px;
      margin-top: -30px;
      opacity: .75;
      padding: 15px 5px;
      text-align: center;
      top: 50%;
      -webkit-transition: opacity .5s ease;
      transition: opacity .5s ease;
      width: 35px;
      z-index: 1;
      cursor: pointer;
      box-sizing: border-box;

      &::before {
        padding: 15px 5px;
        content: "x";
        text-indent: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-family: sonyngp !important;
        line-height: 1em;
        font-size: 30px;
        content: "x";
        text-align: center;
        text-indent: 0;
        color: #0a5e9d;
      }
    }

    .swiper-button-next {
      left: auto;
      right: -55px;

      &::before {
        content: "y";
        text-indent: -1px;
      }
    }
  }
}

.relatedlink {
  padding: 8rem 1.5rem;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  .related-link-title {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }

    h2 {
      margin-bottom: 4rem;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  .related-link-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }

    .related-link-content {
      @media screen and (max-width: 992px) {
        padding-bottom: 5rem;
      }

      .swiper-pagination {
        display: none;
        @media screen and (max-width: 992px) {
          display: block;
        }
      }
    }

    .related-link-item {
      padding-left: .2rem;
      padding-right: .2rem;
      width: 100%;
      box-sizing: border-box;
      display: block;
      float: left;
      cursor: pointer;
      @media screen and (min-width: 425px) {
        width: 50%;
      }
      @media screen and (min-width: 480px) {
        width: 33.3333% !important;
      }
      @media screen and (min-width: 768px) {
        width: 25% !important;
      }
      @media screen and (min-width: 1024px) {
        width: 20% !important;
      }

      &:hover {
        .img {
          border-color: #7ac3d3;
        }
      }

      .related-link-image {
        margin-bottom: 1.5rem;
        width: 100%;

        img {
          width: 100%;
          border: .1rem solid #e1f0f5;
        }
      }

      h3 {
        color: #2c3e50;
        margin-bottom: .5rem;
        max-width: 95%;
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: .5rem;
      }

      p {
        color: #282c36;
        line-height: 2.4rem;
        max-width: 95%;
        word-wrap: break-word;
        margin-bottom: 0;
        font-weight: 700;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      height: 60px;
      left: -55px;
      margin-top: -30px;
      opacity: .75;
      padding: 15px 5px;
      text-align: center;
      top: 50%;
      -webkit-transition: opacity .5s ease;
      transition: opacity .5s ease;
      width: 35px;
      z-index: 1;
      cursor: pointer;
      box-sizing: border-box;

      &::before {
        padding: 15px 5px;
        content: "x";
        text-indent: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-family: sonyngp !important;
        line-height: 1em;
        font-size: 30px;
        content: "x";
        text-align: center;
        text-indent: 0;
        color: #0a5e9d;
      }
    }

    .swiper-button-next {
      left: auto;
      right: -55px;

      &::before {
        content: "y";
        text-indent: -1px;
      }
    }
  }
}

.backtotop {
  button {
    position: fixed;
    width: 7.1rem !important;
    height: 4.6rem !important;
    bottom: 4rem;
    right: 2.5rem;
    background-color: #fff;
    color: #0a5e9d;
    text-align: center;
    padding: 0 !important;
    margin: 0;
    font-size: 2.6rem;
    //border: .1rem solid #0a5e9d;
    border-radius: .3rem;
    opacity: .6;
    z-index: 999;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
    visibility: hidden;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);

    &:hover {
      -webkit-transition: none;
      transition: none;
      background-color: #fff;
      border: .2rem solid #0a5e9d;
      opacity: 1;
    }

    i {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &::before {
        content: "\f106";
      }
    }
  }
}

.listitem {
  padding: 8rem 1.5rem 3rem 1.5rem;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  overflow: hidden;

  .list-title, .list-item-container,.list-button-container {
    box-sizing: border-box;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    text-align: center;
    overflow: hidden;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
  }

  .list-title {
    h1, p {
      margin-left: 8.33333%;
      display: block;
      width: 83.33333%;
      float: left;
      padding-right: 15px;
      padding-left: 15px;
      box-sizing: border-box;
    }

    h1 {
      font-size: 3.2rem;
      line-height: 3.6rem;
      margin-bottom: 1.5rem;
    }

    p {
      font-family: SST W20 Light, Verdana, sans-serif;
      font-size: 1.6rem;
      line-height: 2.4rem;
      word-wrap: break-word;
      margin-bottom: 1rem;
    }
  }

  .list-item-container {
    padding-top: 3.5rem;
    display: flex;
    flex-wrap: wrap;
    .list-item {
      margin-bottom: .8rem;
      padding-left: .4rem;
      padding-right: .4rem;
      float: left;
      box-sizing: border-box;
      &.model{
        a{
          min-height:unset!important;
        }
      }
      a {
        background-color: #fcfcfe;
        border: .1rem solid #f3f6fa;
        height: 100%;
        min-height: 35rem;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        display: block;
        text-decoration: none;
        .list-image {
          background-color: #fff;
          padding: 0 0 2rem;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          height: 24rem;
        }
      }

      &.list {
        width: 100%;
        @media screen and (min-width: 425px) {
          width: 50% !important;
        }
        @media screen and (min-width: 768px) {
          width: 100% !important;
        }
        @media screen and (min-width: 992px) {
          width: 33.3333% !important;
        }
        a{
          .list-item-title {
            background-color: #d9ecf1;
            color: #2c3e50;
            font-size: 1.6rem;
            font-weight: 700;
            padding: 1.2rem 1.7rem;
            -webkit-transition: background-color .5s ease;
            transition: background-color .5s ease;
            text-align: left;
          }

          .list-desc {
            ul {
              color: #555;
              padding: 1.5rem 2.5rem;
              display: block;
              margin: 0;
              text-align: left;
              li {
                font-weight: 400;
                padding-bottom: .5rem;
                margin: 0;
                list-style: inside;
              }
            }
          }
          &:hover{
            background-color: #e1f0f5;
            border-color: #e1f0f5;
            .list-item-title{
              background-color: #b5dce5;
            }
          }
        }

      }
      &.model {
        width: 100%;
        @media screen and (min-width: 425px) {
          width: 50% !important;
        }
        @media screen and (min-width: 768px) {
          width: 100% !important;
        }
        @media screen and (min-width: 992px) {
          width: 25% !important;
        }
        a{
          .list-item-title {
            color: #2c3e50;
            font-size: 1.6rem;
            padding: 1.2rem 1.7rem .6rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: left;
            background-color: inherit;
            span{
              background-color: #2c3e50;
              border-radius: 0;
              font-size: 1.2rem;
              font-weight: 700;
              padding: .3rem .5rem;
              position: relative;
              text-transform: uppercase;
              top: -1px;
              color: #fff;
              line-height: 1;
              text-align: center;
              white-space: nowrap;
              vertical-align: baseline;
              display: inline;
            }
          }
          .list-desc {
            color: #4a4a4a;
            padding: 0 1.7rem 1.5rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-bottom: 1rem;
            text-align: left;
            height: 14.4rem;
            overflow: hidden;
            box-sizing: border-box;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            line-clamp: 6;
            -webkit-box-orient: vertical;
          }
          &:hover{
            background-color: #e1f0f5;
            border-color: #e1f0f5;
          }
        }
      }
      &.status02{
        display: none;
      }
    }
  }
  .list-button-container{
    text-align: left;
    padding-top:2rem;
    padding-bottom: 2rem;
    a{
      background-color: transparent;
      border-radius: 0;
      -webkit-box-shadow: none!important;
      box-shadow: none!important;
      font-size: 16px;
      margin-right: 16px;
      margin-bottom: 25px;
      min-width: 120px;
      padding: 12px 30px;
      text-align: center;
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
      box-sizing: border-box;
      border: 1px solid #2c3e50;
      color: #2c3e50;
      display: none;
      &:hover{
        background-color: #2c3e50;
        color: #fff;
      }
    }
  }
}
