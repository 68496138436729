.container-v2{
  &.bg-gray{
    background-color: #f4f7fa;
  }
  &.bg-deep-green{
    background-color: #2c3e50;
  }
  &.bg-car-gray{
    background-color: #f2f5f9;
  }
  &.zeroLineHeight{
    line-height: 0;
  }

  &.flex{
    >.cmp-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &.flex-row{
    >.cmp-container {
      flex-direction: row;
    }
  }

  &.flex-column{
    >.cmp-container {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    &.flex-start-mobile {
      > .cmp-container {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &.flex-center-mobile {
      > .cmp-container {
        justify-content: center;
        align-items: center;
      }
    }

    &.flex-end-mobile {
      > .cmp-container {
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &.flex-start {
      > .cmp-container {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &.flex-center {
      > .cmp-container {
        justify-content: center;
        align-items: center;
      }
    }

    &.flex-end {
      > .cmp-container {
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }

  &.position{
    >.cmp-container{
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 767px) {
    &.position-top-mobile {
      > .cmp-container {
        top: 0%;
        transform: translate(-50%) !important;
      }
    }

    &.position-bottom-mobile {
      > .cmp-container {
        top: auto;
        bottom:0%;
        transform: translate(-50%) !important;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    &.position-top{
      >.cmp-container{
        top: 0%;
        transform: translate(-50%) !important;
      }
    }

    &.position-bottom{
      >.cmp-container{
        top: auto;
        bottom:0%;
        transform: translate(-50%) !important;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    &.position-top{
      >.cmp-container{
        top: 0%;
        transform: translate(-50%) !important;
      }
    }

    &.position-bottom{
      >.cmp-container{
        top: auto;
        bottom:0%;
        transform: translate(-50%) !important;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    &.position-top{
      >.cmp-container{
        top: 0%;
        transform: translate(-50%) !important;
      }
    }

    &.position-bottom{
      >.cmp-container{
        top: auto;
        bottom:0%;
        transform: translate(-50%) !important;
      }
    }
  }

  >.cmp-container {
    width:100%;
    padding:1.5rem;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    >.new.newpar{
      width:100%;
      float:left;
    }

  }
  &.no-padding{
    >.cmp-container{
      padding:0;
    }
  }
  &.center{
    >.cmp-container {
      margin-left:auto;
      margin-right:auto;
      @media screen and (min-width: 768px) {
        width: 75rem !important;
      }
      @media screen and (min-width: 992px) {
        width: 97rem !important;
      }
      @media screen and (min-width: 1200px) {
        width: 123.8rem !important;
      }
    }
  }
  &.mobile-half-width{
    float:left;
    width:50%!important;
  }
  &.mobile-one-third-width{
    float:left;
    width:33.33% !important;
  }
  &.mobile-quarter-width{
    float:left;
    width:25%!important;
  }
  @media screen and (min-width: 768px) {
    &.tablet-half-width{
      float:left;
      width:50%!important;
    }
    &.tablet-one-third-width{
      float:left;
      width:33.33% !important;
    }
    &.tablet-quarter-width{
      float:left;
      width:25%!important;
    }
  }
  @media screen and (min-width: 1200px) {
    &.pc-half-width{
      float:left;
      width:50%!important;
    }
    &.pc-one-third-width{
      float:left;
      width:33.33% !important;
    }
    &.pc-quarter-width{
      float:left;
      width:25%!important;
    }
  }
}
