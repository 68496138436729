.site-map-container{
  padding:8rem 1.5rem 3.5rem 1.5rem;
  box-sizing: border-box;
  .site-map-inner-container{
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left:1.5rem;
    padding-right:1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }

    .title{
      padding-left:1.5rem;
      padding-right:1.5rem;
      .cmp-title__text{
        font-size: 32px;
        line-height: 36px;
        text-align: left;
      }
    }
    .button{
      padding-left:1.5rem;
      padding-right:1.5rem;
      padding-bottom:3.5rem;
      a{
        color: #1b79a7;
        font-weight: 400;
        font-size: 2.8rem;
        line-height: 3.2rem;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .sitemap{
      padding-right: 15px;
      padding-left: 15px;
      width: 25%;
      box-sizing: border-box;
      padding-bottom:5rem;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }
      a{
        display: block;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: #555;
        font-weight: 700;
        &:hover{
          text-decoration: underline;
        }
      }
      &>a{
        font-size: 20px;
        line-height: 26px;
        color:#1b79a7;
        margin-bottom:2rem;
      }
      ul{
        padding-left:1rem;
        margin:0;
      }
      &>ul{
        padding:0;
        margin:0;
      }
    }
  }
}