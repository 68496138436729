.h5-video{
  .desktop,
  .mobile {
    position: relative;
    video{
      width:100%;
    }
    .voice-btn {
      position: absolute;
      top: 48px;
      right: 34px;
      z-index: 999;
      cursor: pointer;
      display: none!important;
      @media screen and (max-width: 768px) {
        top: 24px;
        right: 16px;
      }

      .icon {
        width: 20px;
        height: 20px;
        display: none;

        &.show {
          display: block;
        }
      }
    }
  }

/*  .fds-activity-indicator {
    z-index: 3999;
  }*/
  // min-height: 200px;
  .videoContainer{
    width: 100%;
  }
  .mobile{
    @media screen and (max-width: 768px) {
      display: block;
      position: relative;
    }
    @media screen and (min-width: 767px) {
      display: none;
    }
  }
  .desktop{
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 767px) {
      display: block;
      position: relative;
    }
  }
  .videoImg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .mobile{
    //video::-webkit-media-controls-container {
    //  display: none !important;
    //  -webkit-appearance: none !important;
    //}
    //video::-webkit-media-controls {
    //  display: none !important;
    //}
    //video::-webkit-play-pause {
    //  display: none !important;
    //}
    //video::-webkit-media-controls-play-button {
    //  display: none !important;
    //  -webkit-appearance: none !important;
    //}
    button {
      -webkit-appearance: none  !important;
    }
    video{
      .media-controls-container{
        display: none !important;
      }
      .media-controls{
        display: none !important;
      }
      .play-pause{
        display: none !important;
      }
    }
    //video::-webkit-media-controls-timeline {}
    //video::-webkit-media-controls-current-time-display{}
    //video::-webkit-media-controls-time-remaining-display {}
    //video::-webkit-media-controls-mute-button {}
    //video::-webkit-media-controls-toggle-closed-captions-button {}
    //video::-webkit-media-controls-volume-slider {}
  }
  .h5_video{
    position: relative;
    width: 100%;
    .mask{
      background-color: #000000;
      position: absolute;
      height: 100%;
      width: 100%;;
      opacity: 0.5;
      z-index: 9;
    }
    .videoSize{
      height: auto;
      width: 100%;;
      display: block;
    }
    .controlBar{
      width: 15%;
      height:50px;
      position: absolute;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .control{
        position: absolute;
        display: block;
        /*bottom: 12px;
        right: 20px;*/
        width: 32px;
        height: 32px;
        /*border: 1px solid #fff;*/
        /*border-radius: 50%;*/
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .control:hover{
        /*background-color: #0276b3;*/
      }
      .btn-control::before{
        content: '';
        font-size: 16px;
        margin: 0;
        position: absolute;
        transform: translate(-50%, -51%) rotate(180deg);
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }
      .playing::before{
        border-style: solid;
        border-width: 20px 50px 20px 0;
        border-color: transparent #fff transparent transparent;
        line-height: 13px;
        text-shadow: none;
        display: block;
        width: 13px;
        height: 13px;
        top: 50%;
        left: 60%;
        cursor: pointer;
      }
      .paused::before{
        line-height: 32px;
        border: 0;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        height: 30px;
        top: 50%;
        left: 50%;
        width: 18px;
        cursor: pointer;
      }
    }
    .textContainer{
      position: absolute;
      display: flex;
      align-items: center;
      top: 0px;
      color: red;
      width: 100%;
      height: 100%;
      .textCenter{
        margin: 0 auto;
      }
    }
  }
}
/*.billboardbtnvideo{
  position: absolute;
  z-index: 1000;
  top: 10%;
  left: 5%;
  width: 90%;
  @media screen and (max-width: 767px){
    width: 80%;
    top: 10px;
    left: 10%;
  }
  video{
    display: block;
  }
  .video-js{
    width: 100%;
    height: auto;
    @media screen and (max-width: 767px){
      height: 174px;
    }
  }
  .overlay-close{
    position: absolute;
    right: -17px;
    z-index: 1100;
    background-color: gray;
    height: 2rem;
  }
}*/
/*.fds-flex__items--start{
  z-index: 100;
}*/
button.center{
  display: none !important;
  -webkit-appearance : none !important ;
}
