#footer{
  background:#000;
  width:100%;
  height: auto;
  color:#fff;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    height: auto !important;
  }
  .text{
    font-weight: 700;
    margin: 0;
    text-decoration: none;
    color: #fff;
    p{
      margin:0;
      font-family: SST W20 Medium,Verdana,sans-serif;
    }
  }
  .button{
    font-weight: 700;
    margin: 0;
    text-decoration: none;
    color: #fff;
    a{
      color: #fff;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .footer-top-container{
    /*width: 50%;*/
    margin-bottom:2rem;
    /*margin-left:auto;
    margin-right:auto;*/
    /*overflow: hidden;*/
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &>.container{
      &>.cmp-container{
        display: flex;
        @media screen and (max-width: 425px) {
          flex-flow: column;
        }
        &>.container{
          width: 30%;
          float:left;
          margin-left:1.5%;
          margin-right:1.5%;
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top:1rem;
            margin-bottom:1rem;
          }
        }
      }
    }
    .footer-social-container{
      position: relative;
      .button{
        margin: 0 .5rem .5rem;
        float: right;
        a{
          display: block;
          background-color: #fff;
          border-radius: 100px;
          width: 3rem;
          height: 3rem;
          text-align: center;
          .cmp-button__icon--weibo{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            &::before{
              content: "\f18a";
              color:#000;
              font-size: 2rem;
              line-height: 3rem;
            }
          }
          .cmp-button__icon--weixin{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            &::before{
              content: "\f1d7";
              color:#000;
              font-size: 2rem;
              line-height: 3rem;
            }
          }
          .cmp-button__icon--sonyDisplay{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            &::before{
              content: "\f26c";
              color:#000;
              font-size: 2rem;
              line-height: 3rem;
              padding-left:.15rem;
            }
          }
        }
      }
      img{
        position: absolute;
        /*width: 12rem;*/
        /*top:-15rem;*/
        bottom:3rem;
        display: none;
        z-index: 999;
        right:0;
        width: auto!important;
        &.show{
          display: block!important;
        }
      }
    }
  }
  .footer-middle-container{
    border-top: 1px solid #969696;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .button,.text{
      display: inline-block;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width:auto;
      p,span{
        font-size: 1.2rem;
        color: #fff;
      }
      &:last-child{
        float: right;
      }
    }
  }
  .footer-bottom-container{
    .button,.text{
      display: inline-block;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width:auto;
      p,span{
        font-size: 1.2rem;
        color: #6a6a6a;
      }
    }
    .button{
      a{
        color:#6a6a6a;
        /*text-decoration: #6a6a6a;*/
      }
    }
    .cmp-button__icon--police{
      position: relative;
      &::before{
        content:"";
        position: absolute;
        bottom: 0;
        left: -25px;
        width: 2rem;
        height: 2rem;
        display: block;
        background: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/footer-icon_11.png") no-repeat;
      }
    }
    #footer-agreement{
      span{
        cursor: pointer;
        color:#fff;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    #footer-agreement-bottom{
      display: none;
    }
  }
}
.footer-container{
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 3.0rem;
  @media screen and (min-width: 768px) {
    width: 75rem !important;
  }
  @media screen and (min-width: 992px) {
    width: 97rem !important;
  }
  @media screen and (min-width: 1200px) {
    width: 129.8rem !important;
  }
}
