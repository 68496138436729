.container-v2{
  .cmp-title {
    h1{
      font-size: 32px;
      line-height: 36px;
    }
    h2{
      font-size: 28px;
      line-height: 32px;
    }
    h3{
      font-size: 24px;
      line-height: 30px;
    }
    h4{
      font-size: 20px;
      line-height: 26px;
    }
    h5{
      font-size: 16px;
      line-height: 24px;
    }
    h6{
      font-size: 14px;
      line-height: 24px;
    }
    margin-bottom:.5rem;
  }
  .title.font-weight-bold{
    .cmp-title__text {
      font-weight: bold!important;
    }
  }
  .cmp-title__text {
    text-align: inherit!important;
    font-weight: inherit!important;
    padding:0!important;
    margin:0!important;
  }
  .cmp-title__link {
    color:transparent;
    text-decoration: underline;
  }
}
