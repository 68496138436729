.overlay-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 99999;
  top:0;
  left:0;
  .overlay{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    &.mask{
      background-color: rgba(0,0,0,0.3);
    }
    .overlay-item{
      background-color: #fff;
      padding: 1.5rem;
      width: 95vw;
      position: relative;
      .overlay-close{
        width:2.8rem;
        height: 3rem;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        position: absolute;
        top:1.5rem;
        right:1.5rem;
        &::before{
          content: "P";
          font-family: sonyngp!important;
          font-style: normal!important;
          font-weight: 400!important;
          -webkit-font-feature-settings: normal!important;
          font-feature-settings: normal!important;
          font-variant: normal!important;
          text-transform: none!important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color:#000;
          font-size: 2.8rem;
          line-height: 2.8rem;
          cursor: pointer;
        }
        &:hover{
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
  }
  &.overlay-large{
    .overlay-item{
      @media screen and (min-width: 1200px) {
        width: 56rem !important;
      }
    }
  }
  &.overlay-medium{
    .overlay-item{
      @media screen and (min-width: 1200px) {
        width: 46rem !important;
      }
    }
  }
  &.overlay-small{
    .overlay-item{
      @media screen and (min-width: 1200px) {
        width: 36rem !important;
      }
    }
  }
  &.no-padding{
    .overlay-item{
      padding:0;
    }
  }

  &.out-close{
    .overlay-close{
      top: -2.5rem!important;
      right: -3.5rem!important;
      @media screen and (max-width: 768px) {
        top: -3.5rem!important;
        right: 0rem!important;
      }
    }
  }
}

body.xf-web-container{
  .overlay-container{
    display: block;
  }
}
