.container-v2{
  .button{
    /*padding:15px;*/
    display: inline-block;
    >.cmp-button {
      cursor: pointer;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 0;
      -webkit-box-shadow: none!important;
      box-shadow: none!important;
      color: #fff;
      font-size: 16px;
      margin: 7.5px;
      min-width: 120px;
      padding: 12px 30px;
      text-align: center;
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
      display: inline-block;
      &:hover{
        background-color: #fff;
        color: #333;
      }
    }
    &.yellow{
      >.cmp-button {
        background-color: #5886F5;
        border: 2px solid #5886F5;
        color: #fff;
        border-radius: 25px;
        &:hover{
          // background-color: #2c3e50;
          // border: 2px solid #ffd200;
          // color: #ffd200;
        }
      }
    }
    &.gray{
      >.cmp-button {
        border: 2px solid #333;
        color: #333;
        &:hover{
          background-color: #333;
          color: #fff;
        }
      }
    }
    &.no-outline{
      >.cmp-button{
        border:none!important;
        margin:0;
        padding: 0;
        text-align: left;
        margin-bottom:.5rem;
        &:hover{
          color: #fff!important;
          text-decoration: underline;
          background-color: transparent!important;
        }
      }
    }
    &.no-outline.yellow{
      >.cmp-button{
        background-color: transparent!important;
        border:none!important;
        &:hover{
          color: #2c3e50!important;
          text-decoration: underline;
          background-color: transparent!important;
        }
      }
    }
    &.no-outline.gray{
      >.cmp-button{
        border:none!important;
        &:hover{
          color: #333!important;
          text-decoration: underline;
          background-color: transparent!important;
        }
      }
    }

  }
  &>.cmp-container{
    &>.button:first-of-type{
      .cmp-button{
        margin-left:0;
      }
    }
  }

  &>.cmp-container{
    &>.button:last-of-type{
      .cmp-button{
        margin-right:0;
      }
    }
  }
}
.cmp-button__text {}
.cmp-button__icon {}
.cmp-button__icon--myIcon {}
