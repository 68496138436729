#header {
  background: #000;
  height: 7.2rem;
  width:100%;
  #header-container {
    /*width: 114rem;*/
    margin: 0 auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 100%;
    /*overflow: hidden;*/
    box-sizing: border-box;
    @media screen and (max-width: 773px) {
      margin-left:auto;
      margin-right:auto;
      position: relative;
      overflow:initial;
    }
    //@media screen and (max-width: 992px) and (min-width : 773px) {
    //  width: 75rem;
    //}
    @media screen and (min-width: 1200px) {
      width: 123.8rem;
    }
    & > .button {
      width: 11rem;
      height: 3rem;
      /* padding: 0.95rem 7.5rem 2rem 3rem; */
      margin-left: 0;
      max-width: 11rem;
      float: left;
      margin-top: 1.95rem;
      margin-right: 7.5rem;

      & > a {
        width: 100%;
        height: 100%;
        display: block;

        & > .cmp-button__icon--logo {
          width: 100%;
          height: 100%;
          display: block;
          background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/sony.svg");
          background-repeat: no-repeat;
          background-position-y: center;
        }
      }
    }

    #header-menu {
      float: left;
      height: 7.2rem;
      @media screen and (max-width: 1070px) {
        display: none;
      }
      & > .aem-Grid {
        height:100%;
        /*display: flex;*/
        & > .container{
          height: 100%;
          width:auto !important;
          & > .cmp-container{
            height: 100%;
            & > .button {
              padding: 2.25rem 2.2rem 2.25rem 0;
              font-size: 1.4rem;
              width: auto;
              position: relative;
              & > button,& > a {
                cursor: pointer;
                & > span{
                  color:#fff;
                  font-weight: 700;
                  line-height:2.7rem;
                  text-align: left;
                  font-family: SST W20 Roman,Verdana,sans-serif;
                }
              }
              &.hover{
                &::after{
                  background-color: #81dcf7;
                  bottom: 0;
                  content: "";
                  display: block;
                  height: .3rem;
                  left: 0;
                  position: absolute;
                  -webkit-transition: all .5s ease;
                  transition: all .5s ease;
                  width: 100%;
                  width: calc(100% - 2.2rem);
                  opacity: 1;
                  visibility:visible;
                }
              }
              &::after{
                background-color: #81dcf7;
                bottom: 0;
                content: "";
                display: block;
                height: .3rem;
                left: 0;
                opacity: 0;
                position: absolute;
                -webkit-transition: all .5s ease;
                transition: all .5s ease;
                visibility: hidden;
                width: 100%;
                width: calc(100% - 30px);
              }
              &:hover{
                &::after{
                  opacity: 1;
                  visibility:visible;
                }
              }
            }
            &>.experiencefragment>.cmp-experiencefragment{
              .main-menu{
                width:120rem;
                padding:3rem 1.5rem .5rem 1.5rem;
                background-color: #2c3e50;
                /*display: none;*/
                position: absolute;
                left: calc(50% - 60.3rem);
                -webkit-transition: all .5s ease;
                transition: all .5s ease;
                opacity: 0;
                visibility: hidden;
                z-index: 2;
                top:7.2rem;
                //@media screen and (max-width: 992px) and (min-width : 773px) {
                //  width: 75rem;
                //}
                @media screen and (max-width: 1200px) and (min-width : 992px) {
                  width: 97rem;
                }
                &>.aem-Grid{
                  &>.container{
                    margin-bottom:2.5rem;
                    padding:0 2.5rem;
                    .menu-item{
                      &>.aem-Grid{
                        &>.image{
                          max-width:5.5rem;
                          margin:0 0 1rem 0;
                          padding: 0;
                        }
                        &>.button{
                          a{
                            text-decoration: none;
                            span{
                              color: #fff;
                              font-size: 1.6rem;
                              font-weight: 700;
                              line-height: 2.4rem;
                              &:hover{
                                text-decoration: underline;
                              }
                            }
                          }
                        }
                        &>.button.menu-first-item{
                          span{
                            color:#81dcf7;
                          }
                        }
                      }
                    }
                  }
                }
                .secondary-menu{
                  margin-top: 2rem;
                  border-top: .1rem solid #81dcf7;
                  padding-top: 2rem;
                  span{
                    color: #81dcf7;
                    font-size: 1.6rem;
                    font-weight: 700;
                    text-decoration: none;
                    line-height: 2.4rem;
                    &:hover{
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #header-search {
      float: right;
      height: 7.2rem;
      position: relative;
      @media screen and (max-width: 773px) {
        width: 2.4rem;
        height: 2rem;
        padding:2.5rem 1.5rem 2.5rem 1.5rem;
        position: absolute;
        right: 13rem;
        top: -0.4rem;
      }
      .button{
        width:5.8rem;
        height:7.2rem;
        &.menu-button {
          padding: 2.25rem 0 2.25rem 0;
          font-size: 1.4rem;
          width: auto !important;
          position: relative;
          span{
            color: #fff;
            font-weight: 700;
            line-height: 2.7rem;
            text-align: left;
            font-family: SST W20 Roman, Verdana, sans-serif;
          }
        }
        @media screen and (max-width: 773px) {
          width:auto;
          height:auto;
          &.menu-button {
            width: 60px;
            position: absolute;
            right: -12rem;
            padding: 0;
          }
        }
        button{
          padding: 2.35rem 1.5rem;
          width: 100%;
          height: 100%;
          display: block;
          cursor: pointer;
          @media screen and (max-width: 773px) {
            padding:0;
          }
          .cmp-button__icon--search{
            background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/nav_search.png");
            background-repeat: no-repeat;
            background-position-y: -2px;
            display: block;
            width: 2.8rem;
            height: 2.5rem;
            background-size: cover;
          }
          .cmp-button__icon--menu-close{
            width:2.8rem;
            height: 3rem;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: all .5s ease;
            transition: all .5s ease;
            display: inline-block;
            &:before{
              content: "P";
              font-family: sonyngp!important;
              font-style: normal!important;
              font-weight: 400!important;
              -webkit-font-feature-settings: normal!important;
              font-feature-settings: normal!important;
              font-variant: normal!important;
              text-transform: none!important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color:#fff;
              font-size: 2.8rem;
              line-height: 2.8rem;
            }
            &:hover{
              -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
            }
          }
        }
      }
      .searchbar{
        background-color: #fff;
        color: #555;
        display: block;
        /*max-width: 100%;*/
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 100%;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        visibility: hidden;
        z-index: 99;
        &::after{
          border-color: transparent transparent #81dcf7;
          border-style: solid;
          border-width: 0 10px 10px;
          /* bottom: 0; */
          content: "";
          display: block;
          height: 0;
          /* left: 50%; */
          margin-left: -10px;
          opacity: 0;
          position: absolute;
          -webkit-transition: all .5s ease;
          transition: all .5s ease;
          visibility: hidden;
          width: 0;
          top: -10px;
          right: 20px;
        }
        @media screen and (min-width: 773px) {
          width: 54rem !important;
        }
        @media screen and (max-width: 773px) {
          top:7.6rem;
          right: -12.96rem;
        }
        .typeahead-wrapper {
          background-color: #fff;
          .typeahead-default {
            background-color: #2c3e50;
            border: 1px solid #81dcf7;
            color: #e1f0f5;
            padding: 9px 20px;
            .sr-only{
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              border: 0;
            }
            input{
              background-color: transparent;
              border: 0;
              color: #e1f0f5;
              width: 100%;
            }
            input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
              color : #e1f0f5;
            }
            :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
              color : #e1f0f5;
            }
            ::-moz-placeholder { /* Mozilla Firefox 19+ */
              color : #e1f0f5;
            }
            input:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color : #e1f0f5;
            }
            input::-ms-input-placeholder { /* Microsoft Edge */
              color : #e1f0f5;
            }
          }
          .typeahead-results {
            color: #555;
            padding: 0;
            display: none;
            height: 500px;
            overflow: auto;
            ul{
              margin-top: 0;
              margin-bottom: 10px;
              padding:0;
              li{
                border-bottom: 1px solid #f3f6fb;
                overflow: hidden;
                padding: 15px 15px 15px 18px;
                position: relative;
                h5{
                  font-size: 14px;
                  font-weight: 400;
                  margin-bottom: 0;
                  max-width: 250px;
                }
                p{
                  line-height: 18px;
                  margin-bottom: 0;
                  max-width: 250px;
                }
                img{
                  max-height: 80px;
                  max-width: 110px;
                  position: absolute;
                  right: 25px;
                  top: 10px;
                  display: block;
                  padding: 4px;
                  margin-bottom: 20px;
                  line-height: 1.42857;
                  background-color: #fff;
                  border: 1px solid #ddd;
                  border-radius: 4px;
                  -webkit-transition: border .2s ease-in-out;
                  transition: border .2s ease-in-out;
                }
                &>a{
                  display: block;
                  height: 100%;
                  left: 0;
                  position: absolute;
                  text-indent: -9999px;
                  top: 0;
                  width: 100%;
                }
                &:hover{
                  background-color: #f3f6fb;
                }
                &.active{
                  background-color: #f3f6fb;
                }
              }
              &.list-products{
                li{
                  padding: 5px 5px 5px 18px;
                  a{
                    text-indent: 0;
                    position: initial;
                    color: #555;
                  }
                }
              }
            }
            .show-all-results{
              display: block;
              font-weight: 700;
              margin-bottom: 15px;
              padding: 0 18px;
              color: #0a5e9d;
              font-size: 13px;
            }
          }
        }
        &.active{
          opacity: 1;
          visibility: visible;
          &::after{
            opacity: 1;
            visibility: visible;
            @media screen and (max-width: 773px) {
              opacity: 0 !important;
              visibility: hidden !important;
            }
          }
        }
      }
    }
    .menuclose{
      display: none;
      button{
        height: 3.2rem;
        width: 4.2rem;
        position: absolute;
        right: 7.5rem;
        top: 1.7rem;
        padding: .9rem 1rem;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        .icon-bar{
          display: block;
          width: 2.2rem;
          height: .2rem;
          border-radius: .1rem;
          background-color: #fff;
          +.icon-bar{
            margin-top: .4rem;
          }
          transition: all .5s ease;
        }
        .sr-only{
          position: absolute;
          width: .1rem;
          height: .1rem;
          padding: 0;
          margin: -0.1rem;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }
        &:not(.collapsed){
          height: 3.2rem;
          right: 7.1rem;
          width: 2.8rem;
          top:1.8rem;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
          .icon-bar{
            margin-top: 0;
            position: absolute;
            right: -0.5rem;
            top: 1.5rem;
            -webkit-transform: rotate(-45deg) scale(.7);
            transform: rotate(-45deg) scale(.7);
            width: 4.2rem;
          }
          .icon-bar-2,.icon-bar-3{
            bottom: 1.5rem;
            top: auto;
            -webkit-transform: rotate(45deg) scale(.7);
            transform: rotate(45deg) scale(.7);
          }
        }
      }
      @media screen and (max-width: 773px) {
        display: block;
      }
    }
    .mobile-nav{
      @media screen and (min-width: 773px) {
        display: none;
      }
      @media screen and (max-width: 773px) {
        display: none;
        opacity: 0;
        position: absolute;
        width: 100%;
        right: 0;
        left: 0;
        top: 7rem;
        z-index: 10;
        overflow: hidden;
        &.search-show{
          top:11.6rem;
        }
        .mobile-nav-container{
          padding-left: 0;
          padding-right: 0;
          position: relative;
          -webkit-transition: left .5s ease;
          transition: left .5s ease;
          margin-right: auto;
          margin-left: auto;
          box-sizing: border-box;
          ul{
            float: left;
            padding: 2.5rem 5rem 3.5rem;
            position: relative;
            -webkit-transition: left .5s ease;
            transition: left .5s ease;
            margin:0;
            box-sizing: border-box;
            background-color: #2c3e50;
            li{
              margin-bottom: 2.5rem;
              position: relative;
              &.title{
                border-bottom:.1rem solid #81dcf7;
                padding-bottom:2.5rem;
                button{
                  &::after{
                    content: "x"!important;
                    left: -3.1rem!important;
                    right:auto;
                  }
                }
              }
              &.all{
                border-top:.1rem solid #81dcf7;
                padding-top:2.5rem;
              }
              button,a{
                font-size: 1.6rem;
                font-weight: 700;
                line-height:2.4rem;
                color:#fff;
                text-decoration: none;
                width: 100%;
                text-align: left;
              }
              button{
                &::after{
                  font-family: sonyngp!important;
                  font-style: normal!important;
                  font-weight: 400!important;
                  -webkit-font-feature-settings: normal!important;
                  font-feature-settings: normal!important;
                  font-variant: normal!important;
                  text-transform: none!important;
                  speak: none;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  content: "y";
                  right: -3.1rem;
                  position: absolute;
                  line-height: 1.4rem;
                  font-size: 1.4rem;
                  top: .3rem;
                }
              }
              &:last-child{
                margin-bottom:0;
              }
            }
          }
        }
      }
    }
  }
}

body.xf-web-container{
  .main-menu{
    background-color: #2c3e50;
    img{
      width: 5.5rem;
    }
    .cmp-button__text{
      color:#fff;
    }
  }

  #header{
    height: auto;
  }
}
