.cmp-carousel {}
.cmp-carousel__content {}
.cmp-carousel__item {}
.cmp-carousel__actions {}
.cmp-carousel__action {}
.cmp-carousel__action--disabled {}
.cmp-carousel__action--previous {}
.cmp-carousel__action--next {}
.cmp-carousel__action--pause {}
.cmp-carousel__action--play {}
.cmp-carousel__action-icon {}
.cmp-carousel__action-text {}
.cmp-carousel__indicators {}
.cmp-carousel__indicator {}

.swiper{
  overflow: hidden;
}