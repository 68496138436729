.container-v2{
  .text.font-size-large{
    .cmp-text {
      p{
        font-size: 1.8rem;
      }
    }
  }
  .text.font-size-medium{
    .cmp-text {
      p{
        font-size: 1.6rem;
      }
    }
  }
  .text.font-size-small{
    .cmp-text {
      p{
        font-size: 1.4rem;
      }
    }
  }
  .text.font-weight-bold{
    .cmp-text {
      p{
        font-weight: bold;
      }
    }
  }
  .cmp-text{
    margin-bottom:.5rem;
  }
}
.cmp-text__paragraph {}
