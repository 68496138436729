.root .cmp-container main {
  overflow: unset!important;
}
.searchbanner{
  padding:4rem 1.5rem;
  box-sizing: border-box;
  .search-banner-title{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    h1{
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 15px;
      margin-top: 0;
      display: block;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.searchinputbar{
  padding-top:2.5rem;
  padding-bottom:2.5rem;
  background-color: #2c3e50;
  color: #fff;
  .search-input-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 117rem !important;
    }
    .search-bar-container{
      padding-left:1.5rem;
      @media screen and (min-width: 992px) {
        padding-left:0;
      }
      .typeahead-wrapper{
        position: relative;
        width: 66.66667%;
        float: left;
        @media screen and (max-width: 992px) {
          width: 100% !important;
        }
        .sr-only{
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }
        input{
          display: inline-block;
          vertical-align: middle;
          background-color: transparent;
          border: 1px solid #959ea7;
          padding: 10px 8px;
          -webkit-transition: border .5s ease;
          transition: border .5s ease;
          padding-right: 65px;
          border-radius: 0;
          color: #fff;
          font-size: 16px;
          height: auto;
          width: 100%;
          box-sizing: border-box;
          &:focus{
            outline-color: #3b99fc;
            outline-offset: -2px;
            outline-style: auto;
            outline-width: 5px;
            box-shadow: none;
          }
        }
        input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color : #e1f0f5;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color : #e1f0f5;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color : #e1f0f5;
        }
        input:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color : #e1f0f5;
        }
        input::-ms-input-placeholder { /* Microsoft Edge */
          color : #e1f0f5;
        }
        .typeahead-results{
          background-color: #fff;
          box-shadow: 2px 2px 5px 0 rgba(0,0,0,.25);
          color: #555;
          display: none;
          left: 0;
          position: absolute;
          top: 45px;
          width: 100%;
          height: 500px;
          overflow: auto;
          z-index: 60;
          ul{
            margin-top: 0;
            margin-bottom: 10px;
            padding:0;
            li{
              padding: 16px;
              position: relative;
              &:hover{
                background-color: #f3f6fa;
              }
              h5{
                color: #555;
                font-weight: 400;
                margin-bottom: 0;
                font-size: 16px;
              }
              a{
                height: 100%;
                left: 0;
                overflow: hidden;
                position: absolute;
                text-indent: -9999px;
                top: 0;
                width: 100%;
              }
            }
          }
        }
        &::after{
          content: "R";
          display: block;
          font-family: sonyngp!important;
          font-size: 34px;
          height: 100%;
          line-height: 44px;
          pointer-events: none;
          position: absolute;
          right: 0;
          text-align: center;
          top: .3rem;
          width: 50px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .search-button-container{
      text-align: right;
      width: 33.33333%;
      float: left;
      padding-right:1.5rem;
      box-sizing: border-box;
      @media screen and (max-width: 992px) {
        width: 100% !important;
        text-align: center !important;
        margin-top: 20px;
      }
      button{
        background-color: transparent;
        border: 1px solid #f3f6fb;
        border-radius: 0;
        -webkit-box-shadow: none!important;
        box-shadow: none!important;
        color: #f3f6fb;
        font-size: 16px;
        margin-right: 16px;
        margin-bottom: 25px;
        min-width: 120px;
        padding: 12px 30px;
        text-align: center;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        font-size: 16px;
        margin-bottom: 0;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        line-height: 1.42857;
        user-select: none;
        &:hover{
          color:#2c3e50;
          background-color: #fff;
        }
      }
    }
  }
}
.searchresult{
  padding-left:15px;
  padding-right:15px;
  background-color: #ffffff;
  .detail-container{
    margin-bottom: 4rem;
  }
  .search-result-title{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 121rem !important;
    }
    h2{
      width: 100%;
      margin-top:3rem;
      margin-bottom:2rem;
      font-size: 28px;
      line-height: 32px;
      box-sizing: border-box;
    }
  }
  .search-result-container{
    display: flex;
    width:100%;
    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }
    @media screen and (min-width: 768px) {
      width: 75rem !important;
    }
    @media screen and (min-width: 992px) {
      width: 97rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 121rem !important;
    }
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    &.professional {
      .search-result-item {
        padding-top: 0;
      }
      .search-result-image{
        img {
          border: unset!important;
        }
      }
    }
    &.product {
      .search-result-item{
        .search-result-detail{
          p{
            height: 48px;
            -webkit-line-clamp:2;
          }
        }
      }
    }
    .search-result-item{
      width:48%;
      border: 1px solid #eaeaea;
      padding-top: 36px;
      position: relative;
      margin-right: 22.5px;
      padding-bottom: 20px;
      @media screen and (max-width: 767px) {
        margin-right: 2%;
        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }
      @media screen and (min-width: 768px) {
        width: 22.6rem !important;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-right: 36px;
        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
      @media screen and (min-width: 992px) {
        width: 21.8rem !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        margin-right: 32.6px;
        &:nth-child(4n+4) {
          margin-right: 0;
        }
      }
      @media screen and (min-width: 1200px) {
        width: 22.4rem!important;
        &:nth-child(5n+5) {
          margin-right: 0;
        }
      }
      margin-bottom: 10px;
      box-sizing: border-box;
      overflow: hidden;
      .icons_lists{
        height: 36px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        img {
          float: right;
          width: 56px !important;
          height: 15px;
          margin-top: 10px;
          margin-right: 10px;
        }
        .ioncs_status1 {
          background: #c60001;
          font-size: 18px;
          color: #ffffff;
          height: 36px;
          line-height: 36px;
          float: left;
          padding: 0 12px 0 12px;
          width: 72px;
          text-align: center;
          box-sizing: unset;
        }
        .ioncs_status2 {
          background: #0A83D6;
          font-size: 18px;
          color: #ffffff;
          height: 36px;
          line-height: 36px;
          float: left;
          text-align: center;
          padding: 0 12px;
          box-sizing: unset;
        }
        .ioncs_status3 {
          background: #2c3e50;
          font-size: 18px;
          color: #ffffff;
          height: 36px;
          line-height: 36px;
          float: left;
          padding: 0 12px 0 12px;
          width: 72px;
          text-align: center;
          box-sizing: unset;
        }
        .installmentIcon {
          position: absolute;
          top: 5px;
          left: 0;
          height: 29px;
          background: #fe8f35;
          line-height: 29px;
          padding: 0 10px;
          color: #ffffff;
          font-size: 13px;
        }
      }
      .search-result-image{
        width : 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        aspect-ratio: 1;
        position: relative;
        /*@media screen and (max-width: 992px) {
          display: none;
        }*/
        img{
          max-width: 100%;
          //height: fit-content;
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
          width: 100%;
          vertical-align: middle;
          height: auto;
        }
      }
      .search-result-detail{
        width: 100%;
        box-sizing: border-box;

        @media screen and (max-width: 992px) {
          width: 100% !important;
        }
        h3{
          font-size: 18px;
          height: 60px;
          line-height: 30px;
          margin:0 10px 15px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          a{
            color: #030303;
            text-decoration: none;
            font-weight: bold;
            &:hover{
              color: #7ac3d3;
            }
            em{
              font-style: normal;
            }
          }
          span{
            font-size: 1.2rem;
            margin-left: 2rem;
            top: -3px;
            background-color: #2c3e50;
            border-radius: 0;
            font-weight: 700;
            padding: 3px 5px;
            position: relative;
            text-transform: uppercase;
            display: inline;
            line-height: 1;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
          }
        }
        p{
          padding: 0 10px;
          color: #000000;
          height: 72px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .pro_pirces{
          font-size: 16px;
          margin: 0 10px;
          color: #0A83D6;
          height: 48px;
          font{
            font-size: 24px;
          }
          em{
            font-style: normal;
          }
        }
      }
    }
  }

  .button-list{
    display: flex;
    justify-content: center;
    .search-result-more,.search-result-less{
      margin: 0  0 40px 0;
      box-sizing: border-box;
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
      display: none;
      button{
        color: #2c3e50;
        font-weight: 700;
        font-size: 18px;
        padding: 8px 28px;
        background-color: transparent;
        border: 1px solid #2c3e50;
        border-radius: 0;
        box-shadow: none!important;
        margin-right: 16px;
        margin-bottom: 25px;
        min-width: 120px;
        text-align: center;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        display: inline-block;
        cursor: pointer;
        &:hover{
          background-color: #2c3e50;
          color: #fff;
        }
      }
    }
  }
  .professional-search-container{
    .button-list{
      .search-result-more,.search-result-less {
        margin: 0;
      }
    }
  }
}
