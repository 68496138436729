@charset "UTF-8";
@font-face {
  font-family: SST W20 Light;
  src: url(../resources/fonts/SST-W20-Light.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Light.eot?#iefix) format("eot"), url(../resources/fonts/SST-W20-Light.woff2) format("woff2"), url(../resources/fonts/SST-W20-Light.woff) format("woff"), url(../resources/fonts/SST-W20-Light.ttf) format("truetype"), url(../resources/fonts/SST-W20-Light.svg#SST-W20-Light) format("svg");
}
@font-face {
  font-family: SST W20 Roman;
  src: url(../resources/fonts/SST-W20-Roman.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Roman.eot?#iefix) format("eot"), url(../resources/fonts/SST-W20-Roman.woff2) format("woff2"), url(../resources/fonts/SST-W20-Roman.woff) format("woff"), url(../resources/fonts/SST-W20-Roman.ttf) format("truetype"), url(../resources/fonts/SST-W20-Roman.svg#SST-W20-Roman) format("svg");
}
@font-face {
  font-family: SST W20 Medium;
  src: url(../resources/fonts/SST-W20-Medium.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Medium.eot?#iefix) format("eot"), url(../resources/fonts/SST-W20-Medium.woff2) format("woff2"), url(../resources/fonts/SST-W20-Medium.woff) format("woff"), url(../resources/fonts/SST-W20-Medium.ttf) format("truetype"), url(../resources/fonts/SST-W20-Medium.svg#SST-W20-Medium) format("svg");
}
@font-face {
  font-family: SST W20 Bold;
  src: url(../resources/fonts/SST-W20-Bold.eot?#iefix);
  src: url(../resources/fonts/SST-W20-Bold.eot?#iefix) format("eot"), url(../resources/fonts/SST-W20-Bold.woff2) format("woff2"), url(../resources/fonts/SST-W20-Bold.woff) format("woff"), url(../resources/fonts/SST-W20-Bold.ttf) format("truetype"), url(../resources/fonts/SST-W20-Bold.svg#SST-W20-Bold) format("svg");
}
@font-face {
  font-family: sonyngp;
  src: url(../resources/fonts/sony-ngp.eot);
  src: url(../resources/fonts/sony-ngp.eot?#iefix) format("embedded-opentype"), url(../resources/fonts/sony-ngp.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FontAwesome;
  src: url(../resources/fonts/fontawesome-webfont.eot);
  src: url(../resources/fonts/fontawesome-webfont.eot?#iefix) format("embedded-opentype"), url(../resources/fonts/fontawesome-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
html,
body {
  margin: 0;
  font-size: 10px;
  font-family: Microsoft YaHei, sans-serif;
  line-height: 1.5;
  color: #202020;
  background: #FFF;
  /* &.nav-show{
       background-color:rgba(0,0,0,.45);
   }*/
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}
@media (prefers-color-scheme: dark) {
  html,
  body {
    color: #dfdfdf;
    background: black;
  }
}

a {
  color: #2020E0;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  a {
    color: #dfdf1f;
  }
}

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  padding: 0;
  border: none;
  background: none;
}

li {
  list-style: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: Microsoft YaHei, sans-serif;
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-weight: 500;
}

p {
  margin: 0;
  color: inherit;
  font-family: Microsoft YaHei, sans-serif;
}

body, div > span, h3 > span, label > span, p {
  font-family: Microsoft YaHei, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

#accessory.cq-Editable-dom, .gallery.cq-Editable-dom, #introduction.cq-Editable-dom, #property.cq-Editable-dom, #specification.cq-Editable-dom, #relatedproduct.cq-Editable-dom {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.bottom-more {
  padding-bottom: 20px;
}

.top-more {
  padding-top: 20px;
}

.bg-gray {
  background-color: #f4f7fa;
}

.bg-deep-green {
  background-color: #2c3e50;
}

.bg-car-gray {
  background-color: #f2f5f9;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.font-color-black {
  color: #333;
}

.font-color-white {
  color: #fff;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #fff;
    color: #000;
  }
}

.pc-only {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .pc-only {
    display: none !important;
  }
}

.mobile-only {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .mobile-only {
    display: block !important;
  }
}

.container-v2 .button {
  /*padding:15px;*/
  display: inline-block;
}
.container-v2 .button > .cmp-button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff;
  font-size: 16px;
  margin: 7.5px;
  min-width: 120px;
  padding: 12px 30px;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: inline-block;
}
.container-v2 .button > .cmp-button:hover {
  background-color: #fff;
  color: #333;
}
.container-v2 .button.yellow > .cmp-button {
  background-color: #5886F5;
  border: 2px solid #5886F5;
  color: #fff;
  border-radius: 25px;
}
.container-v2 .button.gray > .cmp-button {
  border: 2px solid #333;
  color: #333;
}
.container-v2 .button.gray > .cmp-button:hover {
  background-color: #333;
  color: #fff;
}
.container-v2 .button.no-outline > .cmp-button {
  border: none !important;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 0.5rem;
}
.container-v2 .button.no-outline > .cmp-button:hover {
  color: #fff !important;
  text-decoration: underline;
  background-color: transparent !important;
}
.container-v2 .button.no-outline.yellow > .cmp-button {
  background-color: transparent !important;
  border: none !important;
}
.container-v2 .button.no-outline.yellow > .cmp-button:hover {
  color: #2c3e50 !important;
  text-decoration: underline;
  background-color: transparent !important;
}
.container-v2 .button.no-outline.gray > .cmp-button {
  border: none !important;
}
.container-v2 .button.no-outline.gray > .cmp-button:hover {
  color: #333 !important;
  text-decoration: underline;
  background-color: transparent !important;
}
.container-v2 > .cmp-container > .button:first-of-type .cmp-button {
  margin-left: 0;
}
.container-v2 > .cmp-container > .button:last-of-type .cmp-button {
  margin-right: 0;
}

.swiper {
  overflow: hidden;
}

.cmp-helloworld__item-label {
  margin-bottom: 0;
}

.cmp-helloworld__item-output {
  margin-top: 0;
}

.container-v2 .image .cmp-image img {
  display: block;
}

.cmp-image__image {
  width: 100%;
}

.container-v2 .text.font-size-large .cmp-text p {
  font-size: 1.8rem;
}
.container-v2 .text.font-size-medium .cmp-text p {
  font-size: 1.6rem;
}
.container-v2 .text.font-size-small .cmp-text p {
  font-size: 1.4rem;
}
.container-v2 .text.font-weight-bold .cmp-text p {
  font-weight: bold;
}
.container-v2 .cmp-text {
  margin-bottom: 0.5rem;
}

.container-v2 .cmp-title {
  margin-bottom: 0.5rem;
}
.container-v2 .cmp-title h1 {
  font-size: 32px;
  line-height: 36px;
}
.container-v2 .cmp-title h2 {
  font-size: 28px;
  line-height: 32px;
}
.container-v2 .cmp-title h3 {
  font-size: 24px;
  line-height: 30px;
}
.container-v2 .cmp-title h4 {
  font-size: 20px;
  line-height: 26px;
}
.container-v2 .cmp-title h5 {
  font-size: 16px;
  line-height: 24px;
}
.container-v2 .cmp-title h6 {
  font-size: 14px;
  line-height: 24px;
}
.container-v2 .title.font-weight-bold .cmp-title__text {
  font-weight: bold !important;
}
.container-v2 .cmp-title__text {
  text-align: inherit !important;
  font-weight: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
}
.container-v2 .cmp-title__link {
  color: transparent;
  text-decoration: underline;
}

.container-v2.bg-gray {
  background-color: #f4f7fa;
}
.container-v2.bg-deep-green {
  background-color: #2c3e50;
}
.container-v2.bg-car-gray {
  background-color: #f2f5f9;
}
.container-v2.zeroLineHeight {
  line-height: 0;
}
.container-v2.flex > .cmp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.container-v2.flex-row > .cmp-container {
  flex-direction: row;
}
.container-v2.flex-column > .cmp-container {
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .container-v2.flex-start-mobile > .cmp-container {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .container-v2.flex-center-mobile > .cmp-container {
    justify-content: center;
    align-items: center;
  }
  .container-v2.flex-end-mobile > .cmp-container {
    justify-content: flex-end;
    align-items: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .container-v2.flex-start > .cmp-container {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .container-v2.flex-center > .cmp-container {
    justify-content: center;
    align-items: center;
  }
  .container-v2.flex-end > .cmp-container {
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.container-v2.position > .cmp-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .container-v2.position-top-mobile > .cmp-container {
    top: 0%;
    transform: translate(-50%) !important;
  }
  .container-v2.position-bottom-mobile > .cmp-container {
    top: auto;
    bottom: 0%;
    transform: translate(-50%) !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .container-v2.position-top > .cmp-container {
    top: 0%;
    transform: translate(-50%) !important;
  }
  .container-v2.position-bottom > .cmp-container {
    top: auto;
    bottom: 0%;
    transform: translate(-50%) !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .container-v2.position-top > .cmp-container {
    top: 0%;
    transform: translate(-50%) !important;
  }
  .container-v2.position-bottom > .cmp-container {
    top: auto;
    bottom: 0%;
    transform: translate(-50%) !important;
  }
}
@media screen and (min-width: 1200px) {
  .container-v2.position-top > .cmp-container {
    top: 0%;
    transform: translate(-50%) !important;
  }
  .container-v2.position-bottom > .cmp-container {
    top: auto;
    bottom: 0%;
    transform: translate(-50%) !important;
  }
}
.container-v2 > .cmp-container {
  width: 100%;
  padding: 1.5rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.container-v2 > .cmp-container > .new.newpar {
  width: 100%;
  float: left;
}
.container-v2.no-padding > .cmp-container {
  padding: 0;
}
.container-v2.center > .cmp-container {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .container-v2.center > .cmp-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .container-v2.center > .cmp-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .container-v2.center > .cmp-container {
    width: 123.8rem !important;
  }
}
.container-v2.mobile-half-width {
  float: left;
  width: 50% !important;
}
.container-v2.mobile-one-third-width {
  float: left;
  width: 33.33% !important;
}
.container-v2.mobile-quarter-width {
  float: left;
  width: 25% !important;
}
@media screen and (min-width: 768px) {
  .container-v2.tablet-half-width {
    float: left;
    width: 50% !important;
  }
  .container-v2.tablet-one-third-width {
    float: left;
    width: 33.33% !important;
  }
  .container-v2.tablet-quarter-width {
    float: left;
    width: 25% !important;
  }
}
@media screen and (min-width: 1200px) {
  .container-v2.pc-half-width {
    float: left;
    width: 50% !important;
  }
  .container-v2.pc-one-third-width {
    float: left;
    width: 33.33% !important;
  }
  .container-v2.pc-quarter-width {
    float: left;
    width: 25% !important;
  }
}

#header {
  background: #000;
  height: 7.2rem;
  width: 100%;
}
#header #header-container {
  /*width: 114rem;*/
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 100%;
  /*overflow: hidden;*/
  box-sizing: border-box;
}
@media screen and (max-width: 773px) {
  #header #header-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: initial;
  }
}
@media screen and (min-width: 1200px) {
  #header #header-container {
    width: 123.8rem;
  }
}
#header #header-container > .button {
  width: 11rem;
  height: 3rem;
  /* padding: 0.95rem 7.5rem 2rem 3rem; */
  margin-left: 0;
  max-width: 11rem;
  float: left;
  margin-top: 1.95rem;
  margin-right: 7.5rem;
}
#header #header-container > .button > a {
  width: 100%;
  height: 100%;
  display: block;
}
#header #header-container > .button > a > .cmp-button__icon--logo {
  width: 100%;
  height: 100%;
  display: block;
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/sony.svg");
  background-repeat: no-repeat;
  background-position-y: center;
}
#header #header-container #header-menu {
  float: left;
  height: 7.2rem;
}
@media screen and (max-width: 1070px) {
  #header #header-container #header-menu {
    display: none;
  }
}
#header #header-container #header-menu > .aem-Grid {
  height: 100%;
  /*display: flex;*/
}
#header #header-container #header-menu > .aem-Grid > .container {
  height: 100%;
  width: auto !important;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container {
  height: 100%;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button {
  padding: 2.25rem 2.2rem 2.25rem 0;
  font-size: 1.4rem;
  width: auto;
  position: relative;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button > button, #header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button > a {
  cursor: pointer;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button > button > span, #header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button > a > span {
  color: #fff;
  font-weight: 700;
  line-height: 2.7rem;
  text-align: left;
  font-family: SST W20 Roman, Verdana, sans-serif;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button.hover::after {
  background-color: #81dcf7;
  bottom: 0;
  content: "";
  display: block;
  height: 0.3rem;
  left: 0;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
  width: calc(100% - 2.2rem);
  opacity: 1;
  visibility: visible;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button::after {
  background-color: #81dcf7;
  bottom: 0;
  content: "";
  display: block;
  height: 0.3rem;
  left: 0;
  opacity: 0;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  visibility: hidden;
  width: 100%;
  width: calc(100% - 30px);
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .button:hover::after {
  opacity: 1;
  visibility: visible;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu {
  width: 120rem;
  padding: 3rem 1.5rem 0.5rem 1.5rem;
  background-color: #2c3e50;
  /*display: none;*/
  position: absolute;
  left: calc(50% - 60.3rem);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  top: 7.2rem;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  #header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu {
    width: 97rem;
  }
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu > .aem-Grid > .container {
  margin-bottom: 2.5rem;
  padding: 0 2.5rem;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu > .aem-Grid > .container .menu-item > .aem-Grid > .image {
  max-width: 5.5rem;
  margin: 0 0 1rem 0;
  padding: 0;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu > .aem-Grid > .container .menu-item > .aem-Grid > .button a {
  text-decoration: none;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu > .aem-Grid > .container .menu-item > .aem-Grid > .button a span {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu > .aem-Grid > .container .menu-item > .aem-Grid > .button a span:hover {
  text-decoration: underline;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu > .aem-Grid > .container .menu-item > .aem-Grid > .button.menu-first-item span {
  color: #81dcf7;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu .secondary-menu {
  margin-top: 2rem;
  border-top: 0.1rem solid #81dcf7;
  padding-top: 2rem;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu .secondary-menu span {
  color: #81dcf7;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  line-height: 2.4rem;
}
#header #header-container #header-menu > .aem-Grid > .container > .cmp-container > .experiencefragment > .cmp-experiencefragment .main-menu .secondary-menu span:hover {
  text-decoration: underline;
}
#header #header-container #header-search {
  float: right;
  height: 7.2rem;
  position: relative;
}
@media screen and (max-width: 773px) {
  #header #header-container #header-search {
    width: 2.4rem;
    height: 2rem;
    padding: 2.5rem 1.5rem 2.5rem 1.5rem;
    position: absolute;
    right: 13rem;
    top: -0.4rem;
  }
}
#header #header-container #header-search .button {
  width: 5.8rem;
  height: 7.2rem;
}
#header #header-container #header-search .button.menu-button {
  padding: 2.25rem 0 2.25rem 0;
  font-size: 1.4rem;
  width: auto !important;
  position: relative;
}
#header #header-container #header-search .button.menu-button span {
  color: #fff;
  font-weight: 700;
  line-height: 2.7rem;
  text-align: left;
  font-family: SST W20 Roman, Verdana, sans-serif;
}
@media screen and (max-width: 773px) {
  #header #header-container #header-search .button {
    width: auto;
    height: auto;
  }
  #header #header-container #header-search .button.menu-button {
    width: 60px;
    position: absolute;
    right: -12rem;
    padding: 0;
  }
}
#header #header-container #header-search .button button {
  padding: 2.35rem 1.5rem;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 773px) {
  #header #header-container #header-search .button button {
    padding: 0;
  }
}
#header #header-container #header-search .button button .cmp-button__icon--search {
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/nav_search.png");
  background-repeat: no-repeat;
  background-position-y: -2px;
  display: block;
  width: 2.8rem;
  height: 2.5rem;
  background-size: cover;
}
#header #header-container #header-search .button button .cmp-button__icon--menu-close {
  width: 2.8rem;
  height: 3rem;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: inline-block;
}
#header #header-container #header-search .button button .cmp-button__icon--menu-close:before {
  content: "P";
  font-family: sonyngp !important;
  font-style: normal !important;
  font-weight: 400 !important;
  -webkit-font-feature-settings: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 2.8rem;
  line-height: 2.8rem;
}
#header #header-container #header-search .button button .cmp-button__icon--menu-close:hover {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
#header #header-container #header-search .searchbar {
  background-color: #fff;
  color: #555;
  display: block;
  /*max-width: 100%;*/
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  visibility: hidden;
  z-index: 99;
}
#header #header-container #header-search .searchbar::after {
  border-color: transparent transparent #81dcf7;
  border-style: solid;
  border-width: 0 10px 10px;
  /* bottom: 0; */
  content: "";
  display: block;
  height: 0;
  /* left: 50%; */
  margin-left: -10px;
  opacity: 0;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  visibility: hidden;
  width: 0;
  top: -10px;
  right: 20px;
}
@media screen and (min-width: 773px) {
  #header #header-container #header-search .searchbar {
    width: 54rem !important;
  }
}
@media screen and (max-width: 773px) {
  #header #header-container #header-search .searchbar {
    top: 7.6rem;
    right: -12.96rem;
  }
}
#header #header-container #header-search .searchbar .typeahead-wrapper {
  background-color: #fff;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default {
  background-color: #2c3e50;
  border: 1px solid #81dcf7;
  color: #e1f0f5;
  padding: 9px 20px;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default input {
  background-color: transparent;
  border: 0;
  color: #e1f0f5;
  width: 100%;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #e1f0f5;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #e1f0f5;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #e1f0f5;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #e1f0f5;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-default input::-ms-input-placeholder { /* Microsoft Edge */
  color: #e1f0f5;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results {
  color: #555;
  padding: 0;
  display: none;
  height: 500px;
  overflow: auto;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li {
  border-bottom: 1px solid #f3f6fb;
  overflow: hidden;
  padding: 15px 15px 15px 18px;
  position: relative;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li h5 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 250px;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li p {
  line-height: 18px;
  margin-bottom: 0;
  max-width: 250px;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li img {
  max-height: 80px;
  max-width: 110px;
  position: absolute;
  right: 25px;
  top: 10px;
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li > a {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  text-indent: -9999px;
  top: 0;
  width: 100%;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li:hover {
  background-color: #f3f6fb;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul li.active {
  background-color: #f3f6fb;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul.list-products li {
  padding: 5px 5px 5px 18px;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results ul.list-products li a {
  text-indent: 0;
  position: initial;
  color: #555;
}
#header #header-container #header-search .searchbar .typeahead-wrapper .typeahead-results .show-all-results {
  display: block;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0 18px;
  color: #0a5e9d;
  font-size: 13px;
}
#header #header-container #header-search .searchbar.active {
  opacity: 1;
  visibility: visible;
}
#header #header-container #header-search .searchbar.active::after {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 773px) {
  #header #header-container #header-search .searchbar.active::after {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
#header #header-container .menuclose {
  display: none;
}
#header #header-container .menuclose button {
  height: 3.2rem;
  width: 4.2rem;
  position: absolute;
  right: 7.5rem;
  top: 1.7rem;
  padding: 0.9rem 1rem;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#header #header-container .menuclose button .icon-bar {
  display: block;
  width: 2.2rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  background-color: #fff;
  transition: all 0.5s ease;
}
#header #header-container .menuclose button .icon-bar + .icon-bar {
  margin-top: 0.4rem;
}
#header #header-container .menuclose button .sr-only {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  padding: 0;
  margin: -0.1rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
#header #header-container .menuclose button:not(.collapsed) {
  height: 3.2rem;
  right: 7.1rem;
  width: 2.8rem;
  top: 1.8rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
#header #header-container .menuclose button:not(.collapsed) .icon-bar {
  margin-top: 0;
  position: absolute;
  right: -0.5rem;
  top: 1.5rem;
  -webkit-transform: rotate(-45deg) scale(0.7);
  transform: rotate(-45deg) scale(0.7);
  width: 4.2rem;
}
#header #header-container .menuclose button:not(.collapsed) .icon-bar-2, #header #header-container .menuclose button:not(.collapsed) .icon-bar-3 {
  bottom: 1.5rem;
  top: auto;
  -webkit-transform: rotate(45deg) scale(0.7);
  transform: rotate(45deg) scale(0.7);
}
@media screen and (max-width: 773px) {
  #header #header-container .menuclose {
    display: block;
  }
}
@media screen and (min-width: 773px) {
  #header #header-container .mobile-nav {
    display: none;
  }
}
@media screen and (max-width: 773px) {
  #header #header-container .mobile-nav {
    display: none;
    opacity: 0;
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    top: 7rem;
    z-index: 10;
    overflow: hidden;
  }
  #header #header-container .mobile-nav.search-show {
    top: 11.6rem;
  }
  #header #header-container .mobile-nav .mobile-nav-container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    -webkit-transition: left 0.5s ease;
    transition: left 0.5s ease;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul {
    float: left;
    padding: 2.5rem 5rem 3.5rem;
    position: relative;
    -webkit-transition: left 0.5s ease;
    transition: left 0.5s ease;
    margin: 0;
    box-sizing: border-box;
    background-color: #2c3e50;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li {
    margin-bottom: 2.5rem;
    position: relative;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li.title {
    border-bottom: 0.1rem solid #81dcf7;
    padding-bottom: 2.5rem;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li.title button::after {
    content: "x" !important;
    left: -3.1rem !important;
    right: auto;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li.all {
    border-top: 0.1rem solid #81dcf7;
    padding-top: 2.5rem;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li button, #header #header-container .mobile-nav .mobile-nav-container ul li a {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: #fff;
    text-decoration: none;
    width: 100%;
    text-align: left;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li button::after {
    font-family: sonyngp !important;
    font-style: normal !important;
    font-weight: 400 !important;
    -webkit-font-feature-settings: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "y";
    right: -3.1rem;
    position: absolute;
    line-height: 1.4rem;
    font-size: 1.4rem;
    top: 0.3rem;
  }
  #header #header-container .mobile-nav .mobile-nav-container ul li:last-child {
    margin-bottom: 0;
  }
}

body.xf-web-container .main-menu {
  background-color: #2c3e50;
}
body.xf-web-container .main-menu img {
  width: 5.5rem;
}
body.xf-web-container .main-menu .cmp-button__text {
  color: #fff;
}
body.xf-web-container #header {
  height: auto;
}

.root .cmp-container main {
  overflow: hidden;
}

h2.cmp-title__text {
  font-size: 2.8rem;
  line-height: 3.2rem;
  text-align: center;
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 500;
  padding-top: 6rem;
}
@media screen and (max-width: 425px) {
  h2.cmp-title__text {
    padding-top: 3rem;
  }
}

.solutions-container {
  margin-right: auto !important;
  margin-left: auto !important;
}
@media screen and (min-width: 768px) {
  .solutions-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .solutions-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .solutions-container {
    width: 117rem !important;
  }
}
.solutions-container .solutionitem {
  width: 100%;
  height: 21rem;
  margin-bottom: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  float: left;
}
@media screen and (min-width: 425px) {
  .solutions-container .solutionitem {
    width: 50% !important;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 768px) {
  .solutions-container .solutionitem {
    width: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .solutions-container .solutionitem {
    width: 32.1rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .solutions-container .solutionitem {
    width: 38.7rem !important;
  }
}
.solutions-container .solutionitem a {
  background-color: #2c3e50;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.solutions-container .solutionitem a .title {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  background-color: rgba(44, 62, 80, 0.65) !important;
  color: #fff;
  left: 0;
  padding: 15px;
  position: absolute;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  z-index: 2;
}
.solutions-container .solutionitem a .title h3 {
  color: #fff;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 500;
  display: inline-block;
}
.solutions-container .solutionitem a .title p {
  display: inline-block;
  margin: 0;
}
.solutions-container .solutionitem a:hover::after {
  opacity: 0.25;
}
.solutions-container .solutionitem a::after {
  background-color: #000;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  width: 100%;
  opacity: 0;
}

.root {
  overflow: hidden;
}

.breadcrumb, .solutionBreadcrumb {
  background-color: #f6f6f6;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumb .breadcrumb-container, .solutionBreadcrumb .breadcrumb-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
}
@media screen and (min-width: 768px) {
  .breadcrumb .breadcrumb-container, .solutionBreadcrumb .breadcrumb-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .breadcrumb .breadcrumb-container, .solutionBreadcrumb .breadcrumb-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .breadcrumb .breadcrumb-container, .solutionBreadcrumb .breadcrumb-container {
    width: 116.2rem !important;
  }
}
.breadcrumb .breadcrumb-container ol, .solutionBreadcrumb .breadcrumb-container ol {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  /*height: 4.56rem;*/
}
.breadcrumb .breadcrumb-container ol li, .solutionBreadcrumb .breadcrumb-container ol li {
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: inherit;
  font-size: 1.3rem;
  position: relative;
  display: inline-block;
}
.breadcrumb .breadcrumb-container ol li a, .solutionBreadcrumb .breadcrumb-container ol li a {
  color: #555;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.4rem;
}
.breadcrumb .breadcrumb-container ol li a:hover, .solutionBreadcrumb .breadcrumb-container ol li a:hover {
  color: #00587d;
}
.breadcrumb .breadcrumb-container ol li:not(:last-child)::before, .solutionBreadcrumb .breadcrumb-container ol li:not(:last-child)::before {
  background-color: rgba(90, 91, 93, 0.13);
  color: transparent;
  content: "";
  display: block !important;
  height: 1rem;
  padding: 0;
  position: absolute;
  right: -0.5rem;
  top: 50%;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  width: 0.1rem;
}
.breadcrumb .breadcrumb-container ol li:not(:last-child)::after, .solutionBreadcrumb .breadcrumb-container ol li:not(:last-child)::after {
  background-color: rgba(90, 91, 93, 0.13);
  color: transparent;
  content: "";
  display: block !important;
  height: 1rem;
  padding: 0;
  position: absolute;
  right: -0.5rem;
  top: 50%;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  width: 0.1rem;
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
@media screen and (max-width: 768px) {
  .breadcrumb .breadcrumb-container ol li:not(:last-child), .breadcrumb .breadcrumb-container ol li:not(:first-child), .solutionBreadcrumb .breadcrumb-container ol li:not(:last-child), .solutionBreadcrumb .breadcrumb-container ol li:not(:first-child) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .breadcrumb .breadcrumb-container ol li:last-child, .breadcrumb .breadcrumb-container ol li:first-child, .solutionBreadcrumb .breadcrumb-container ol li:last-child, .solutionBreadcrumb .breadcrumb-container ol li:first-child {
    display: inline-block;
  }
}
.breadcrumb .breadcrumb-container ol li:last-child, .solutionBreadcrumb .breadcrumb-container ol li:last-child {
  vertical-align: bottom;
}
.breadcrumb .breadcrumb-container ol li:last-child a, .solutionBreadcrumb .breadcrumb-container ol li:last-child a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 38rem;
}
@media screen and (max-width: 768px) {
  .breadcrumb .breadcrumb-container ol li:last-child a, .solutionBreadcrumb .breadcrumb-container ol li:last-child a {
    max-width: 24rem;
  }
}

.banner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}
.banner .banner-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 4rem 1.5rem 4rem 1.5rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .banner .banner-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .banner .banner-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .banner .banner-container {
    width: 117rem !important;
  }
}
.banner .banner-container .banner-title {
  width: 50%;
  float: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .banner .banner-container .banner-title {
    width: 100% !important;
  }
}
.banner .banner-container .banner-title h1 {
  font-size: 3.2rem;
  line-height: 3.6rem;
  display: block;
  margin-bottom: 1.5rem;
}
.banner .banner-container .banner-title h1 span {
  font-size: 1.6rem;
  margin-left: 0.5rem;
  top: -0.7rem;
  background-color: #2c3e50;
  border-radius: 0;
  font-weight: 700;
  padding: 3px 5px;
  position: relative;
  text-transform: uppercase;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.banner .banner-container .banner-title p {
  font-size: 2.8rem;
  line-height: 3.2rem;
  display: block;
  margin-bottom: 1.5rem;
}
.banner .banner-container .banner-title a {
  background-color: #ffd200;
  border: 2px solid #ffd200;
  color: #2c3e50;
  margin-right: 1.6rem;
  margin-bottom: 2.5rem;
  min-width: 12rem;
  padding: 1.2rem 3rem;
  text-align: center;
  font-size: 1.6rem;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: inline-block;
  box-sizing: border-box;
}
.banner .banner-container .banner-title a.white {
  border-color: #2c3e50 !important;
  color: #2c3e50 !important;
  background-color: #fff !important;
}
.banner .banner-container .banner-title a.white:hover {
  color: #fff !important;
  background-color: #2c3e50 !important;
}
.banner .banner-container .banner-title a:hover {
  background-color: #2c3e50;
  border: 0.2rem solid #ffd200;
  color: #ffd200;
}
.banner .banner-container .banner-image {
  width: 50%;
  float: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .banner .banner-container .banner-image {
    width: 100% !important;
  }
}
.banner .banner-container .banner-image img {
  max-width: 100%;
  vertical-align: middle;
}

.navigator {
  padding-top: 2rem;
  background-color: #2c3e50;
  border-bottom: 2px solid #f3f6fa;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  /*@media screen and (max-width: 992px) {
    display: none;
  }*/
}
.navigator.fixed {
  position: fixed;
  z-index: 52;
  top: 0;
  display: block !important;
}
.navigator.fixed .navigator-container {
  overflow: initial !important;
}
.navigator .navigator-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  overflow: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow: hidden;
  min-height: 4rem;
}
@media screen and (min-width: 768px) {
  .navigator .navigator-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .navigator .navigator-container {
    width: 97rem !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: unset;
  }
}
@media screen and (min-width: 1200px) {
  .navigator .navigator-container {
    width: 117rem !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: unset;
  }
}
.navigator .navigator-container .navigator-title {
  width: 58.33333%;
  float: left;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-title {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 2rem;
    width: 100%;
    display: none;
  }
}
.navigator .navigator-container .navigator-title h2 {
  font-size: 2.4rem;
}
.navigator .navigator-container .navigator-button {
  max-height: 0px;
  opacity: 0;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
  padding-top: 0.7rem;
  width: 41.66667%;
  float: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  -webkit-transition: max-height 0.5s ease, opacity 0.5s ease, margin 0.5s ease;
  transition: max-height 0.5s ease, opacity 0.5s ease, margin 0.5s ease;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-button {
    display: none;
  }
}
.navigator .navigator-container .navigator-button a {
  display: inline-block;
  border: 0.1rem solid #adadad;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 1.6rem;
  margin-right: 1.6rem;
  margin-bottom: 2.5rem;
  min-width: 12rem;
  padding: 1.2rem 3rem;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  color: #fff;
  box-sizing: border-box;
}
.navigator .navigator-container .navigator-button a.yellow {
  background-color: #ffd200 !important;
  border: 2px solid #ffd200 !important;
  color: #2c3e50 !important;
}
.navigator .navigator-container .navigator-button a.yellow:hover {
  background-color: #2c3e50 !important;
  color: #ffd200 !important;
}
.navigator .navigator-container .navigator-button a:hover {
  background-color: #f3f6fb;
  color: #2c3e50;
}
.navigator .navigator-container .navigator-tabs {
  width: 100%;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  float: left;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-tabs {
    padding-left: 3rem;
    padding-right: 3rem;
    display: none;
  }
}
.navigator .navigator-container .navigator-tabs ul {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.navigator .navigator-container .navigator-tabs ul li {
  float: left;
  margin-bottom: -1px;
  max-width: 33.3333333333%;
  padding-right: 25px;
  width: auto;
  min-height: 19px;
  text-align: left;
  height: 100%;
  display: inline-block;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-tabs ul li {
    max-width: unset;
    width: 100%;
  }
}
.navigator .navigator-container .navigator-tabs ul li a {
  border-bottom: 0;
  color: #fff;
  display: block;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0;
  padding-bottom: 2rem;
  position: relative;
  text-align: left;
  margin-right: 1px;
  border: none;
  height: 100%;
  font-weight: 700;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-tabs ul li a {
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid hsla(0, 0%, 100%, 0.5);
  }
}
.navigator .navigator-container .navigator-tabs ul li a.hover {
  color: #81dcf7 !important;
  border-bottom: none !important;
  background-color: transparent !important;
  cursor: pointer;
}
.navigator .navigator-container .navigator-tabs ul li a.active {
  color: #81dcf7 !important;
  border-bottom: none !important;
  background-color: transparent !important;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-tabs ul li a.active {
    border-top: none;
  }
}
.navigator .navigator-container .navigator-tabs ul li a.active::before {
  background-color: transparent;
  border-color: #2c3e50 transparent transparent;
  border-style: solid;
  border-width: 1.5rem 1.25rem 0;
  bottom: -1.3rem;
  content: "";
  display: block;
  height: 0;
  left: 1rem;
  position: absolute;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  width: 0;
  opacity: 1;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-tabs ul li a.active::before {
    display: none;
  }
}
.navigator .navigator-container .navigator-tabs ul li a::before {
  bottom: 0;
  content: "";
  display: block;
  height: 0.4rem;
  left: 0;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-tabs ul li a::before {
    display: none;
  }
}
.navigator .navigator-container .navigator-mobile-header {
  display: none;
  padding: 0 0 2rem 0;
}
@media screen and (max-width: 992px) {
  .navigator .navigator-container .navigator-mobile-header {
    display: block;
  }
}
.navigator .navigator-container .navigator-mobile-header a {
  display: block;
  position: relative;
  width: 3rem;
  min-height: 1.8rem;
  float: left;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}
.navigator .navigator-container .navigator-mobile-header a.active {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigator .navigator-container .navigator-mobile-header a span {
  width: 30px;
  height: 2px;
  display: block;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
}
.navigator .navigator-container .navigator-mobile-header a span:nth-of-type(2) {
  top: 50%;
  margin-top: -0.1rem;
}
.navigator .navigator-container .navigator-mobile-header a span:nth-of-type(3) {
  bottom: 0;
  top: auto;
}

.gallery-root {
  background-color: #f3f6fb;
  overflow: hidden;
  padding: 2.5rem 2.5rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .gallery-root {
    padding: 6rem 2.5rem;
  }
}
@media screen and (min-width: 992px) {
  .gallery-root {
    padding: 8rem 1.5rem;
  }
}
.gallery-root .gallery-title, .gallery-root .gallery-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media screen and (min-width: 768px) {
  .gallery-root .gallery-title, .gallery-root .gallery-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .gallery-root .gallery-title, .gallery-root .gallery-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .gallery-root .gallery-title, .gallery-root .gallery-container {
    width: 117rem !important;
  }
}
.gallery-root .gallery-title {
  box-sizing: border-box;
}
.gallery-root .gallery-title h2 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 2.8rem;
  line-height: 3.2rem;
}
.gallery-root .gallery-container {
  padding-top: 3.5rem;
  position: relative;
}
.gallery-root .gallery-container .swiper {
  width: 100%;
  padding-bottom: 5rem;
}
.gallery-root .gallery-container .swiper .swiper-wrapper {
  width: 100%;
}
.gallery-root .gallery-container .swiper .swiper-wrapper .swiper-slide {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}
.gallery-root .gallery-container .swiper .swiper-wrapper .swiper-slide .gallery-image {
  background-size: contain;
  background-repeat: no-repeat;
  overflow-y: hidden;
  padding-bottom: 56.25%;
}
.gallery-root .gallery-container .swiper-button-prev, .gallery-root .gallery-container .swiper-button-next {
  position: absolute;
  height: 60px;
  left: -20px;
  margin-top: -30px;
  opacity: 0.75;
  padding: 15px 5px;
  text-align: center;
  top: 50%;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  width: 35px;
  z-index: 1;
  cursor: pointer;
  box-sizing: border-box;
}
.gallery-root .gallery-container .swiper-button-prev::before, .gallery-root .gallery-container .swiper-button-next::before {
  padding: 15px 5px;
  content: "x";
  text-indent: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: sonyngp !important;
  line-height: 1em;
  font-size: 30px;
  content: "x";
  text-align: center;
  text-indent: 0;
  color: #0a5e9d;
}
@media screen and (max-width: 992px) {
  .gallery-root .gallery-container .swiper-button-prev, .gallery-root .gallery-container .swiper-button-next {
    display: none;
  }
}
.gallery-root .gallery-container .swiper-button-next {
  left: auto;
  right: -20px;
}
.gallery-root .gallery-container .swiper-button-next::before {
  content: "y";
  text-indent: -1px;
}

.introduction-root {
  padding: 8rem 1.5rem;
}
.introduction-root .intro-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .introduction-root .intro-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .introduction-root .intro-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .introduction-root .intro-container {
    width: 117rem !important;
  }
}
.introduction-root .intro-container .intro-title {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  width: 16.66667%;
  float: left;
}
@media screen and (max-width: 992px) {
  .introduction-root .intro-container .intro-title {
    width: 100% !important;
  }
}
.introduction-root .intro-container .intro-title h2 {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.2rem;
}
.introduction-root .intro-container .intro-content-container {
  width: 83.33333%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  float: left;
}
@media screen and (max-width: 992px) {
  .introduction-root .intro-container .intro-content-container {
    width: 100% !important;
  }
}
.introduction-root .intro-container .intro-content-container h3 {
  font-weight: 700;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.introduction-root .intro-container .intro-content-container .intro-content {
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.property-root {
  padding: 8rem 1.5rem;
  background-color: #f3f6fb;
  width: 100%;
  box-sizing: border-box;
}
.property-root .property-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .property-root .property-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .property-root .property-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .property-root .property-container {
    width: 117rem !important;
  }
}
.property-root .property-container .property-title {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  width: 16.66667%;
  float: left;
}
@media screen and (max-width: 992px) {
  .property-root .property-container .property-title {
    width: 100% !important;
  }
}
.property-root .property-container .property-title h2 {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.2rem;
}
.property-root .property-container .property-content-container {
  width: 83.33333%;
  padding: 0;
  box-sizing: border-box;
  float: left;
  position: relative;
}
@media screen and (max-width: 992px) {
  .property-root .property-container .property-content-container {
    width: 100% !important;
  }
}
.property-root .property-container .property-content-container .property-item {
  position: absolute;
  float: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 50%;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
@media screen and (max-width: 992px) {
  .property-root .property-container .property-content-container .property-item {
    width: 100% !important;
  }
}
.property-root .property-container .property-content-container .property-item .property-wrapper {
  background-color: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
}
.property-root .property-container .property-content-container .property-item .property-wrapper h3 {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.property-root .property-container .property-content-container .property-item .property-wrapper .property-content {
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.specDescription {
  margin: -6rem auto 0;
  padding-bottom: 1rem;
  width: 76%;
  word-break: break-all;
}
@media screen and (min-width: 480px) {
  .specDescription {
    width: 82% !important;
  }
}
@media screen and (min-width: 768px) {
  .specDescription {
    width: 69rem !important;
  }
}
@media screen and (min-width: 992px) {
  .specDescription {
    width: 91rem !important;
    padding-left: 15.4rem;
  }
}
@media screen and (min-width: 1200px) {
  .specDescription {
    width: 111rem !important;
    padding-left: 19rem;
  }
}

.specification-root {
  padding: 4.5rem 1.5rem 8rem 1.5rem;
  width: 100%;
  box-sizing: border-box;
}
.specification-root .spec-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .specification-root .spec-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .specification-root .spec-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .specification-root .spec-container {
    width: 117rem !important;
  }
}
.specification-root .spec-container .spec-title {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  width: 16.66667%;
  float: left;
}
@media screen and (max-width: 992px) {
  .specification-root .spec-container .spec-title {
    width: 100% !important;
  }
}
.specification-root .spec-container .spec-title h2 {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.2rem;
  margin-top: 6.9rem;
}
.specification-root .spec-container .spec-content-container {
  width: 83.33333%;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  float: left;
}
@media screen and (max-width: 992px) {
  .specification-root .spec-container .spec-content-container {
    width: 100% !important;
  }
}
.specification-root .spec-container .spec-content-container .spec-button {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .specification-root .spec-container .spec-content-container .spec-button {
    text-align: left;
  }
}
@media screen and (max-width: 480px) {
  .specification-root .spec-container .spec-content-container .spec-button {
    display: none;
  }
}
.specification-root .spec-container .spec-content-container .spec-button button {
  background-color: #f3f6fa;
  border: 0;
  color: #2c3e50;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 1.4rem 6rem 1.4rem 2.5rem;
  position: relative;
  margin-right: 1.6rem;
  margin-bottom: 2.5rem;
  min-width: 12rem;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  box-shadow: none !important;
  border-radius: 0;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
}
.specification-root .spec-container .spec-content-container .spec-button button:hover {
  background-color: #e1f0f5;
}
.specification-root .spec-container .spec-content-container .spec-button button:focus {
  background-color: #e1f0f5;
  outline: 0.5rem auto -webkit-focus-ring-color;
  outline-offset: -0.2rem;
}
.specification-root .spec-container .spec-content-container .spec-button button.btn-expand-all::after {
  color: #2c3e50;
  content: "z";
  font-family: sonyngp !important;
  font-size: 3.2rem;
  height: 1.4rem;
  line-height: 0.8rem;
  margin-top: -0.8rem;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  text-indent: -0.5rem;
  top: 50%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 2.3rem;
  box-sizing: border-box;
}
.specification-root .spec-container .spec-content-container .spec-button button.btn-collapse-all::after {
  color: #2c3e50;
  content: "w";
  font-family: sonyngp !important;
  font-size: 3.2rem;
  height: 1.4rem;
  line-height: 1.6rem;
  margin-top: -0.8rem;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  text-indent: -0.4rem;
  top: 50%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 2.3rem;
  box-sizing: border-box;
}
.specification-root .spec-container .spec-content-container .spec-group {
  margin: 0;
  border-bottom: 0.4rem solid #fff;
}
.specification-root .spec-container .spec-content-container .spec-group > span {
  background-color: #f3f6fb;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 1.8rem;
  padding: 1.5rem 4.3rem 1.5rem 1.9rem;
  position: relative;
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  box-sizing: border-box;
  font-weight: 700;
}
.specification-root .spec-container .spec-content-container .spec-group > span:hover {
  background-color: #e1f0f5;
}
.specification-root .spec-container .spec-content-container .spec-group > span::after {
  color: #2c3e50;
  content: "w";
  display: block;
  font-family: sonyngp !important;
  font-size: 3.2rem;
  height: 1.4rem;
  line-height: 0.8rem;
  margin-top: -0.7rem;
  opacity: 0.5;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  text-decoration: none;
  text-indent: -0.5rem;
  top: 50%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: transform 0.4s ease, opacity 0.4s ease;
  transition: transform 0.4s ease, opacity 0.4s ease, -webkit-transform 0.4s ease;
  width: 2.3rem;
  font-weight: 500;
}
.specification-root .spec-container .spec-content-container .spec-group > span.is-open::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container {
  height: 0;
  transition: height 400ms linear 0s;
  overflow: hidden;
  padding: 0;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container.open {
  padding: 1.5rem 0.7rem;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container .spec-item {
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container .spec-item .spec-key {
  width: 33.33333%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  font-weight: 700;
  float: left;
  min-height: 0.1rem;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container .spec-item .spec-value {
  width: 66.66667%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  word-wrap: break-word;
  float: left;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container .spec-item .spec-bottom {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  float: left;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container .spec-item .spec-bottom span {
  background-color: #bebebe;
  border: 0;
  display: block;
  height: 0.1rem;
  margin: 0.8rem 0;
}
.specification-root .spec-container .spec-content-container .spec-group .spec-item-container .spec-item:last-child .spec-bottom {
  display: none;
}

.accessory-root {
  padding: 8rem 1.5rem 3rem 1.5rem;
  width: 100%;
  box-sizing: border-box;
}
.accessory-root .accy-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .accessory-root .accy-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .accessory-root .accy-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .accessory-root .accy-container {
    width: 117rem !important;
  }
}
.accessory-root .accy-container .accy-title {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  width: 25%;
  float: left;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .accessory-root .accy-container .accy-title {
    width: 100% !important;
  }
}
.accessory-root .accy-container .accy-title h2 {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.2rem;
}
.accessory-root .accy-container .accy-content-container {
  width: 75%;
  float: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (max-width: 992px) {
  .accessory-root .accy-container .accy-content-container {
    width: 100% !important;
  }
}
.accessory-root .accy-container .accy-content-container .accy-item {
  margin-bottom: 1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  display: block;
  width: 100%;
  position: relative;
  /*float: left;*/
  box-sizing: border-box;
  /* 自定义滚动条样式 */
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-item-comment {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: none;
  font-size: 1.4rem;
  overflow-y: scroll;
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-item-comment::-webkit-scrollbar {
  width: 4px;
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-item-comment::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-item-comment::-webkit-scrollbar-thumb {
  background: #000;
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-item-comment::-webkit-scrollbar-thumb:hover {
  background: #000;
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-item-comment-icon {
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/img-remarks.png");
  background-repeat: no-repeat;
  width: 5.6rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  height: 2.6rem;
}
@media screen and (min-width: 425px) {
  .accessory-root .accy-container .accy-content-container .accy-item {
    width: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .accessory-root .accy-container .accy-content-container .accy-item {
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .accessory-root .accy-container .accy-content-container .accy-item {
    width: 25% !important;
  }
}
.accessory-root .accy-container .accy-content-container .accy-item:hover .accy-image {
  border-color: #7ac3d3;
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-image {
  background-color: #fff;
  padding: 2rem 0;
  border: 0.1rem solid #e1f0f5;
}
.accessory-root .accy-container .accy-content-container .accy-item .accy-image img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.accessory-root .accy-container .accy-content-container .accy-item h4 {
  padding: 1.2rem 2rem 0.5rem 0;
  color: #2c3e50;
  text-align: left;
  font-size: 2rem;
  line-height: 2.6rem;
  display: block;
}
.accessory-root .accy-container .accy-content-container .accy-item p {
  color: #4a4a4a;
  font-size: 1.2rem;
  padding: 0 5rem 0 0;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 1rem;
  display: block;
}
.accessory-root .accy-container .accy-more {
  margin-left: 25%;
  width: 75%;
  float: left;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.accessory-root .accy-container .accy-more a {
  display: inline-block;
  font-size: 1.6rem;
  margin-top: 4rem;
  padding-right: 5rem;
  position: relative;
  color: #0a5e9d;
  font-weight: 700;
}
.accessory-root .accy-container .accy-more a:hover {
  text-decoration: underline;
  color: #3c4c74;
}
.accessory-root .accy-container .accy-more a .icon-down-arrows {
  display: block;
  font-size: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  margin-top: -1.9rem;
  position: absolute;
  right: 0;
  top: 50%;
  width: 3.2rem;
}
.accessory-root .accy-container .accy-more a .icon-down-arrows::before {
  content: "z";
  font-family: sonyngp !important;
  font-style: normal !important;
  font-weight: 400 !important;
  -webkit-font-feature-settings: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.relatedproduct {
  padding: 8rem 1.5rem;
  background-color: #f3f6fb;
  width: 100%;
  box-sizing: border-box;
}
.relatedproduct .related-product-title {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media screen and (min-width: 768px) {
  .relatedproduct .related-product-title {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .relatedproduct .related-product-title {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .relatedproduct .related-product-title {
    width: 117rem !important;
  }
}
.relatedproduct .related-product-title h2 {
  margin-bottom: 4rem;
  font-size: 2.8rem;
  line-height: 3.2rem;
}
.relatedproduct .related-product-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .relatedproduct .related-product-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .relatedproduct .related-product-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .relatedproduct .related-product-container {
    width: 117rem !important;
  }
}
@media screen and (max-width: 992px) {
  .relatedproduct .related-product-container .related-product-content {
    padding-bottom: 5rem;
  }
}
.relatedproduct .related-product-container .related-product-content .swiper-pagination {
  display: none;
}
@media screen and (max-width: 992px) {
  .relatedproduct .related-product-container .related-product-content .swiper-pagination {
    display: block;
  }
}
.relatedproduct .related-product-container .related-product-item {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 100%;
  box-sizing: border-box;
  display: block;
  float: left;
  cursor: pointer;
}
@media screen and (min-width: 425px) {
  .relatedproduct .related-product-container .related-product-item {
    width: 50%;
  }
}
@media screen and (min-width: 480px) {
  .relatedproduct .related-product-container .related-product-item {
    width: 33.3333% !important;
  }
}
@media screen and (min-width: 768px) {
  .relatedproduct .related-product-container .related-product-item {
    width: 25% !important;
  }
}
@media screen and (min-width: 1024px) {
  .relatedproduct .related-product-container .related-product-item {
    width: 20% !important;
  }
}
.relatedproduct .related-product-container .related-product-item:hover .img {
  border-color: #7ac3d3;
}
.relatedproduct .related-product-container .related-product-item .related-product-image {
  margin-bottom: 1.5rem;
  width: 100%;
}
.relatedproduct .related-product-container .related-product-item .related-product-image img {
  width: 100%;
  border: 0.1rem solid #e1f0f5;
}
.relatedproduct .related-product-container .related-product-item h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  max-width: 95%;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 0.5rem;
}
.relatedproduct .related-product-container .related-product-item p {
  color: #282c36;
  line-height: 2.4rem;
  max-width: 95%;
  word-wrap: break-word;
  margin-bottom: 0;
  font-weight: 700;
}
.relatedproduct .related-product-container .swiper-button-prev, .relatedproduct .related-product-container .swiper-button-next {
  position: absolute;
  height: 60px;
  left: -55px;
  margin-top: -30px;
  opacity: 0.75;
  padding: 15px 5px;
  text-align: center;
  top: 50%;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  width: 35px;
  z-index: 1;
  cursor: pointer;
  box-sizing: border-box;
}
.relatedproduct .related-product-container .swiper-button-prev::before, .relatedproduct .related-product-container .swiper-button-next::before {
  padding: 15px 5px;
  content: "x";
  text-indent: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: sonyngp !important;
  line-height: 1em;
  font-size: 30px;
  content: "x";
  text-align: center;
  text-indent: 0;
  color: #0a5e9d;
}
.relatedproduct .related-product-container .swiper-button-next {
  left: auto;
  right: -55px;
}
.relatedproduct .related-product-container .swiper-button-next::before {
  content: "y";
  text-indent: -1px;
}

.relatedlink {
  padding: 8rem 1.5rem;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
}
.relatedlink .related-link-title {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media screen and (min-width: 768px) {
  .relatedlink .related-link-title {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .relatedlink .related-link-title {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .relatedlink .related-link-title {
    width: 117rem !important;
  }
}
.relatedlink .related-link-title h2 {
  margin-bottom: 4rem;
  font-size: 2.8rem;
  line-height: 3.2rem;
}
.relatedlink .related-link-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .relatedlink .related-link-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .relatedlink .related-link-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .relatedlink .related-link-container {
    width: 117rem !important;
  }
}
@media screen and (max-width: 992px) {
  .relatedlink .related-link-container .related-link-content {
    padding-bottom: 5rem;
  }
}
.relatedlink .related-link-container .related-link-content .swiper-pagination {
  display: none;
}
@media screen and (max-width: 992px) {
  .relatedlink .related-link-container .related-link-content .swiper-pagination {
    display: block;
  }
}
.relatedlink .related-link-container .related-link-item {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 100%;
  box-sizing: border-box;
  display: block;
  float: left;
  cursor: pointer;
}
@media screen and (min-width: 425px) {
  .relatedlink .related-link-container .related-link-item {
    width: 50%;
  }
}
@media screen and (min-width: 480px) {
  .relatedlink .related-link-container .related-link-item {
    width: 33.3333% !important;
  }
}
@media screen and (min-width: 768px) {
  .relatedlink .related-link-container .related-link-item {
    width: 25% !important;
  }
}
@media screen and (min-width: 1024px) {
  .relatedlink .related-link-container .related-link-item {
    width: 20% !important;
  }
}
.relatedlink .related-link-container .related-link-item:hover .img {
  border-color: #7ac3d3;
}
.relatedlink .related-link-container .related-link-item .related-link-image {
  margin-bottom: 1.5rem;
  width: 100%;
}
.relatedlink .related-link-container .related-link-item .related-link-image img {
  width: 100%;
  border: 0.1rem solid #e1f0f5;
}
.relatedlink .related-link-container .related-link-item h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  max-width: 95%;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 0.5rem;
}
.relatedlink .related-link-container .related-link-item p {
  color: #282c36;
  line-height: 2.4rem;
  max-width: 95%;
  word-wrap: break-word;
  margin-bottom: 0;
  font-weight: 700;
}
.relatedlink .related-link-container .swiper-button-prev, .relatedlink .related-link-container .swiper-button-next {
  position: absolute;
  height: 60px;
  left: -55px;
  margin-top: -30px;
  opacity: 0.75;
  padding: 15px 5px;
  text-align: center;
  top: 50%;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  width: 35px;
  z-index: 1;
  cursor: pointer;
  box-sizing: border-box;
}
.relatedlink .related-link-container .swiper-button-prev::before, .relatedlink .related-link-container .swiper-button-next::before {
  padding: 15px 5px;
  content: "x";
  text-indent: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: sonyngp !important;
  line-height: 1em;
  font-size: 30px;
  content: "x";
  text-align: center;
  text-indent: 0;
  color: #0a5e9d;
}
.relatedlink .related-link-container .swiper-button-next {
  left: auto;
  right: -55px;
}
.relatedlink .related-link-container .swiper-button-next::before {
  content: "y";
  text-indent: -1px;
}

.backtotop button {
  position: fixed;
  width: 7.1rem !important;
  height: 4.6rem !important;
  bottom: 4rem;
  right: 2.5rem;
  background-color: #fff;
  color: #0a5e9d;
  text-align: center;
  padding: 0 !important;
  margin: 0;
  font-size: 2.6rem;
  border-radius: 0.3rem;
  opacity: 0.6;
  z-index: 999;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  visibility: hidden;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.backtotop button:hover {
  -webkit-transition: none;
  transition: none;
  background-color: #fff;
  border: 0.2rem solid #0a5e9d;
  opacity: 1;
}
.backtotop button i {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.backtotop button i::before {
  content: "\f106";
}

.listitem {
  padding: 8rem 1.5rem 3rem 1.5rem;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
}
.listitem .list-title, .listitem .list-item-container, .listitem .list-button-container {
  box-sizing: border-box;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .listitem .list-title, .listitem .list-item-container, .listitem .list-button-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .listitem .list-title, .listitem .list-item-container, .listitem .list-button-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .listitem .list-title, .listitem .list-item-container, .listitem .list-button-container {
    width: 117rem !important;
  }
}
.listitem .list-title h1, .listitem .list-title p {
  margin-left: 8.33333%;
  display: block;
  width: 83.33333%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}
.listitem .list-title h1 {
  font-size: 3.2rem;
  line-height: 3.6rem;
  margin-bottom: 1.5rem;
}
.listitem .list-title p {
  font-family: SST W20 Light, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  word-wrap: break-word;
  margin-bottom: 1rem;
}
.listitem .list-item-container {
  padding-top: 3.5rem;
  display: flex;
  flex-wrap: wrap;
}
.listitem .list-item-container .list-item {
  margin-bottom: 0.8rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  float: left;
  box-sizing: border-box;
}
.listitem .list-item-container .list-item.model a {
  min-height: unset !important;
}
.listitem .list-item-container .list-item a {
  background-color: #fcfcfe;
  border: 0.1rem solid #f3f6fa;
  height: 100%;
  min-height: 35rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  text-decoration: none;
}
.listitem .list-item-container .list-item a .list-image {
  background-color: #fff;
  padding: 0 0 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 24rem;
}
.listitem .list-item-container .list-item.list {
  width: 100%;
}
@media screen and (min-width: 425px) {
  .listitem .list-item-container .list-item.list {
    width: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .listitem .list-item-container .list-item.list {
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .listitem .list-item-container .list-item.list {
    width: 33.3333% !important;
  }
}
.listitem .list-item-container .list-item.list a .list-item-title {
  background-color: #d9ecf1;
  color: #2c3e50;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.2rem 1.7rem;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  text-align: left;
}
.listitem .list-item-container .list-item.list a .list-desc ul {
  color: #555;
  padding: 1.5rem 2.5rem;
  display: block;
  margin: 0;
  text-align: left;
}
.listitem .list-item-container .list-item.list a .list-desc ul li {
  font-weight: 400;
  padding-bottom: 0.5rem;
  margin: 0;
  list-style: inside;
}
.listitem .list-item-container .list-item.list a:hover {
  background-color: #e1f0f5;
  border-color: #e1f0f5;
}
.listitem .list-item-container .list-item.list a:hover .list-item-title {
  background-color: #b5dce5;
}
.listitem .list-item-container .list-item.model {
  width: 100%;
}
@media screen and (min-width: 425px) {
  .listitem .list-item-container .list-item.model {
    width: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .listitem .list-item-container .list-item.model {
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .listitem .list-item-container .list-item.model {
    width: 25% !important;
  }
}
.listitem .list-item-container .list-item.model a .list-item-title {
  color: #2c3e50;
  font-size: 1.6rem;
  padding: 1.2rem 1.7rem 0.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: left;
  background-color: inherit;
}
.listitem .list-item-container .list-item.model a .list-item-title span {
  background-color: #2c3e50;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.3rem 0.5rem;
  position: relative;
  text-transform: uppercase;
  top: -1px;
  color: #fff;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  display: inline;
}
.listitem .list-item-container .list-item.model a .list-desc {
  color: #4a4a4a;
  padding: 0 1.7rem 1.5rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1rem;
  text-align: left;
  height: 14.4rem;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}
.listitem .list-item-container .list-item.model a:hover {
  background-color: #e1f0f5;
  border-color: #e1f0f5;
}
.listitem .list-item-container .list-item.status02 {
  display: none;
}
.listitem .list-button-container {
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.listitem .list-button-container a {
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 16px;
  margin-right: 16px;
  margin-bottom: 25px;
  min-width: 120px;
  padding: 12px 30px;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  box-sizing: border-box;
  border: 1px solid #2c3e50;
  color: #2c3e50;
  display: none;
}
.listitem .list-button-container a:hover {
  background-color: #2c3e50;
  color: #fff;
}

.root .cmp-container main {
  overflow: unset !important;
}

.searchbanner {
  padding: 4rem 1.5rem;
  box-sizing: border-box;
}
.searchbanner .search-banner-title {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .searchbanner .search-banner-title {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .searchbanner .search-banner-title {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .searchbanner .search-banner-title {
    width: 117rem !important;
  }
}
.searchbanner .search-banner-title h1 {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 15px;
  margin-top: 0;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
}

.searchinputbar {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #2c3e50;
  color: #fff;
}
.searchinputbar .search-input-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .searchinputbar .search-input-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .searchinputbar .search-input-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .searchinputbar .search-input-container {
    width: 117rem !important;
  }
}
.searchinputbar .search-input-container .search-bar-container {
  padding-left: 1.5rem;
}
@media screen and (min-width: 992px) {
  .searchinputbar .search-input-container .search-bar-container {
    padding-left: 0;
  }
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper {
  position: relative;
  width: 66.66667%;
  float: left;
}
@media screen and (max-width: 992px) {
  .searchinputbar .search-input-container .search-bar-container .typeahead-wrapper {
    width: 100% !important;
  }
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper input {
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #959ea7;
  padding: 10px 8px;
  -webkit-transition: border 0.5s ease;
  transition: border 0.5s ease;
  padding-right: 65px;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper input:focus {
  outline-color: #3b99fc;
  outline-offset: -2px;
  outline-style: auto;
  outline-width: 5px;
  box-shadow: none;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #e1f0f5;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #e1f0f5;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #e1f0f5;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #e1f0f5;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper input::-ms-input-placeholder { /* Microsoft Edge */
  color: #e1f0f5;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .typeahead-results {
  background-color: #fff;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  color: #555;
  display: none;
  left: 0;
  position: absolute;
  top: 45px;
  width: 100%;
  height: 500px;
  overflow: auto;
  z-index: 60;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .typeahead-results ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .typeahead-results ul li {
  padding: 16px;
  position: relative;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .typeahead-results ul li:hover {
  background-color: #f3f6fa;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .typeahead-results ul li h5 {
  color: #555;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper .typeahead-results ul li a {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-indent: -9999px;
  top: 0;
  width: 100%;
}
.searchinputbar .search-input-container .search-bar-container .typeahead-wrapper::after {
  content: "R";
  display: block;
  font-family: sonyngp !important;
  font-size: 34px;
  height: 100%;
  line-height: 44px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0.3rem;
  width: 50px;
  color: #fff;
  cursor: pointer;
}
.searchinputbar .search-input-container .search-button-container {
  text-align: right;
  width: 33.33333%;
  float: left;
  padding-right: 1.5rem;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .searchinputbar .search-input-container .search-button-container {
    width: 100% !important;
    text-align: center !important;
    margin-top: 20px;
  }
}
.searchinputbar .search-input-container .search-button-container button {
  background-color: transparent;
  border: 1px solid #f3f6fb;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #f3f6fb;
  font-size: 16px;
  margin-right: 16px;
  margin-bottom: 25px;
  min-width: 120px;
  padding: 12px 30px;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  font-size: 16px;
  margin-bottom: 0;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  line-height: 1.42857;
  user-select: none;
}
.searchinputbar .search-input-container .search-button-container button:hover {
  color: #2c3e50;
  background-color: #fff;
}

.searchresult {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
}
.searchresult .detail-container {
  margin-bottom: 4rem;
}
.searchresult .search-result-title {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .searchresult .search-result-title {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .searchresult .search-result-title {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .searchresult .search-result-title {
    width: 121rem !important;
  }
}
.searchresult .search-result-title h2 {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 28px;
  line-height: 32px;
  box-sizing: border-box;
}
.searchresult .search-result-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  .searchresult .search-result-container {
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .searchresult .search-result-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .searchresult .search-result-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .searchresult .search-result-container {
    width: 121rem !important;
  }
}
.searchresult .search-result-container.professional .search-result-item {
  padding-top: 0;
}
.searchresult .search-result-container.professional .search-result-image img {
  border: unset !important;
}
.searchresult .search-result-container.product .search-result-item .search-result-detail p {
  height: 48px;
  -webkit-line-clamp: 2;
}
.searchresult .search-result-container .search-result-item {
  width: 48%;
  border: 1px solid #eaeaea;
  padding-top: 36px;
  position: relative;
  margin-right: 22.5px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .searchresult .search-result-container .search-result-item {
    margin-right: 2%;
  }
  .searchresult .search-result-container .search-result-item:nth-child(2n+2) {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .searchresult .search-result-container .search-result-item {
    width: 22.6rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .searchresult .search-result-container .search-result-item {
    margin-right: 36px;
  }
  .searchresult .search-result-container .search-result-item:nth-child(3n+3) {
    margin-right: 0;
  }
}
@media screen and (min-width: 992px) {
  .searchresult .search-result-container .search-result-item {
    width: 21.8rem !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .searchresult .search-result-container .search-result-item {
    margin-right: 32.6px;
  }
  .searchresult .search-result-container .search-result-item:nth-child(4n+4) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1200px) {
  .searchresult .search-result-container .search-result-item {
    width: 22.4rem !important;
  }
  .searchresult .search-result-container .search-result-item:nth-child(5n+5) {
    margin-right: 0;
  }
}
.searchresult .search-result-container .search-result-item .icons_lists {
  height: 36px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.searchresult .search-result-container .search-result-item .icons_lists img {
  float: right;
  width: 56px !important;
  height: 15px;
  margin-top: 10px;
  margin-right: 10px;
}
.searchresult .search-result-container .search-result-item .icons_lists .ioncs_status1 {
  background: #c60001;
  font-size: 18px;
  color: #ffffff;
  height: 36px;
  line-height: 36px;
  float: left;
  padding: 0 12px 0 12px;
  width: 72px;
  text-align: center;
  box-sizing: unset;
}
.searchresult .search-result-container .search-result-item .icons_lists .ioncs_status2 {
  background: #0A83D6;
  font-size: 18px;
  color: #ffffff;
  height: 36px;
  line-height: 36px;
  float: left;
  text-align: center;
  padding: 0 12px;
  box-sizing: unset;
}
.searchresult .search-result-container .search-result-item .icons_lists .ioncs_status3 {
  background: #2c3e50;
  font-size: 18px;
  color: #ffffff;
  height: 36px;
  line-height: 36px;
  float: left;
  padding: 0 12px 0 12px;
  width: 72px;
  text-align: center;
  box-sizing: unset;
}
.searchresult .search-result-container .search-result-item .icons_lists .installmentIcon {
  position: absolute;
  top: 5px;
  left: 0;
  height: 29px;
  background: #fe8f35;
  line-height: 29px;
  padding: 0 10px;
  color: #ffffff;
  font-size: 13px;
}
.searchresult .search-result-container .search-result-item .search-result-image {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  aspect-ratio: 1;
  position: relative;
  /*@media screen and (max-width: 992px) {
    display: none;
  }*/
}
.searchresult .search-result-container .search-result-item .search-result-image img {
  max-width: 100%;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
  vertical-align: middle;
  height: auto;
}
.searchresult .search-result-container .search-result-item .search-result-detail {
  width: 100%;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .searchresult .search-result-container .search-result-item .search-result-detail {
    width: 100% !important;
  }
}
.searchresult .search-result-container .search-result-item .search-result-detail h3 {
  font-size: 18px;
  height: 60px;
  line-height: 30px;
  margin: 0 10px 15px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.searchresult .search-result-container .search-result-item .search-result-detail h3 a {
  color: #030303;
  text-decoration: none;
  font-weight: bold;
}
.searchresult .search-result-container .search-result-item .search-result-detail h3 a:hover {
  color: #7ac3d3;
}
.searchresult .search-result-container .search-result-item .search-result-detail h3 a em {
  font-style: normal;
}
.searchresult .search-result-container .search-result-item .search-result-detail h3 span {
  font-size: 1.2rem;
  margin-left: 2rem;
  top: -3px;
  background-color: #2c3e50;
  border-radius: 0;
  font-weight: 700;
  padding: 3px 5px;
  position: relative;
  text-transform: uppercase;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.searchresult .search-result-container .search-result-item .search-result-detail p {
  padding: 0 10px;
  color: #000000;
  height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.searchresult .search-result-container .search-result-item .search-result-detail .pro_pirces {
  font-size: 16px;
  margin: 0 10px;
  color: #0A83D6;
  height: 48px;
}
.searchresult .search-result-container .search-result-item .search-result-detail .pro_pirces font {
  font-size: 24px;
}
.searchresult .search-result-container .search-result-item .search-result-detail .pro_pirces em {
  font-style: normal;
}
.searchresult .button-list {
  display: flex;
  justify-content: center;
}
.searchresult .button-list .search-result-more, .searchresult .button-list .search-result-less {
  margin: 0 0 40px 0;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  display: none;
}
.searchresult .button-list .search-result-more button, .searchresult .button-list .search-result-less button {
  color: #2c3e50;
  font-weight: 700;
  font-size: 18px;
  padding: 8px 28px;
  background-color: transparent;
  border: 1px solid #2c3e50;
  border-radius: 0;
  box-shadow: none !important;
  margin-right: 16px;
  margin-bottom: 25px;
  min-width: 120px;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: inline-block;
  cursor: pointer;
}
.searchresult .button-list .search-result-more button:hover, .searchresult .button-list .search-result-less button:hover {
  background-color: #2c3e50;
  color: #fff;
}
.searchresult .professional-search-container .button-list .search-result-more, .searchresult .professional-search-container .button-list .search-result-less {
  margin: 0;
}

.site-map-container {
  padding: 8rem 1.5rem 3.5rem 1.5rem;
  box-sizing: border-box;
}
.site-map-container .site-map-inner-container {
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .site-map-container .site-map-inner-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .site-map-container .site-map-inner-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .site-map-container .site-map-inner-container {
    width: 117rem !important;
  }
}
.site-map-container .site-map-inner-container .title {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.site-map-container .site-map-inner-container .title .cmp-title__text {
  font-size: 32px;
  line-height: 36px;
  text-align: left;
}
.site-map-container .site-map-inner-container .button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3.5rem;
}
.site-map-container .site-map-inner-container .button a {
  color: #1b79a7;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 3.2rem;
}
.site-map-container .site-map-inner-container .button a:hover {
  text-decoration: underline;
}
.site-map-container .site-map-inner-container .sitemap {
  padding-right: 15px;
  padding-left: 15px;
  width: 25%;
  box-sizing: border-box;
  padding-bottom: 5rem;
}
@media screen and (max-width: 992px) {
  .site-map-container .site-map-inner-container .sitemap {
    width: 100% !important;
  }
}
.site-map-container .site-map-inner-container .sitemap a {
  display: block;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #555;
  font-weight: 700;
}
.site-map-container .site-map-inner-container .sitemap a:hover {
  text-decoration: underline;
}
.site-map-container .site-map-inner-container .sitemap > a {
  font-size: 20px;
  line-height: 26px;
  color: #1b79a7;
  margin-bottom: 2rem;
}
.site-map-container .site-map-inner-container .sitemap ul {
  padding-left: 1rem;
  margin: 0;
}
.site-map-container .site-map-inner-container .sitemap > ul {
  padding: 0;
  margin: 0;
}

#footer {
  background: #000;
  width: 100%;
  height: auto;
  color: #fff;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  #footer {
    height: auto !important;
  }
}
#footer .text {
  font-weight: 700;
  margin: 0;
  text-decoration: none;
  color: #fff;
}
#footer .text p {
  margin: 0;
  font-family: SST W20 Medium, Verdana, sans-serif;
}
#footer .button {
  font-weight: 700;
  margin: 0;
  text-decoration: none;
  color: #fff;
}
#footer .button a {
  color: #fff;
  text-decoration: none;
}
#footer .button a:hover {
  text-decoration: underline;
}
#footer .footer-top-container {
  /*width: 50%;*/
  margin-bottom: 2rem;
  /*margin-left:auto;
  margin-right:auto;*/
  /*overflow: hidden;*/
}
@media screen and (max-width: 768px) {
  #footer .footer-top-container {
    width: 100%;
  }
}
#footer .footer-top-container > .container > .cmp-container {
  display: flex;
}
@media screen and (max-width: 425px) {
  #footer .footer-top-container > .container > .cmp-container {
    flex-flow: column;
  }
}
#footer .footer-top-container > .container > .cmp-container > .container {
  width: 30%;
  float: left;
  margin-left: 1.5%;
  margin-right: 1.5%;
}
@media screen and (max-width: 768px) {
  #footer .footer-top-container > .container > .cmp-container > .container {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
#footer .footer-top-container .footer-social-container {
  position: relative;
}
#footer .footer-top-container .footer-social-container .button {
  margin: 0 0.5rem 0.5rem;
  float: right;
}
#footer .footer-top-container .footer-social-container .button a {
  display: block;
  background-color: #fff;
  border-radius: 100px;
  width: 3rem;
  height: 3rem;
  text-align: center;
}
#footer .footer-top-container .footer-social-container .button a .cmp-button__icon--weibo {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
#footer .footer-top-container .footer-social-container .button a .cmp-button__icon--weibo::before {
  content: "\f18a";
  color: #000;
  font-size: 2rem;
  line-height: 3rem;
}
#footer .footer-top-container .footer-social-container .button a .cmp-button__icon--weixin {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
#footer .footer-top-container .footer-social-container .button a .cmp-button__icon--weixin::before {
  content: "\f1d7";
  color: #000;
  font-size: 2rem;
  line-height: 3rem;
}
#footer .footer-top-container .footer-social-container .button a .cmp-button__icon--sonyDisplay {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
#footer .footer-top-container .footer-social-container .button a .cmp-button__icon--sonyDisplay::before {
  content: "\f26c";
  color: #000;
  font-size: 2rem;
  line-height: 3rem;
  padding-left: 0.15rem;
}
#footer .footer-top-container .footer-social-container img {
  position: absolute;
  /*width: 12rem;*/
  /*top:-15rem;*/
  bottom: 3rem;
  display: none;
  z-index: 999;
  right: 0;
  width: auto !important;
}
#footer .footer-top-container .footer-social-container img.show {
  display: block !important;
}
#footer .footer-middle-container {
  border-top: 1px solid #969696;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#footer .footer-middle-container .button, #footer .footer-middle-container .text {
  display: inline-block;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: auto;
}
#footer .footer-middle-container .button p, #footer .footer-middle-container .button span, #footer .footer-middle-container .text p, #footer .footer-middle-container .text span {
  font-size: 1.2rem;
  color: #fff;
}
#footer .footer-middle-container .button:last-child, #footer .footer-middle-container .text:last-child {
  float: right;
}
#footer .footer-bottom-container .button, #footer .footer-bottom-container .text {
  display: inline-block;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: auto;
}
#footer .footer-bottom-container .button p, #footer .footer-bottom-container .button span, #footer .footer-bottom-container .text p, #footer .footer-bottom-container .text span {
  font-size: 1.2rem;
  color: #6a6a6a;
}
#footer .footer-bottom-container .button a {
  color: #6a6a6a;
  /*text-decoration: #6a6a6a;*/
}
#footer .footer-bottom-container .cmp-button__icon--police {
  position: relative;
}
#footer .footer-bottom-container .cmp-button__icon--police::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -25px;
  width: 2rem;
  height: 2rem;
  display: block;
  background: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/footer-icon_11.png") no-repeat;
}
#footer .footer-bottom-container #footer-agreement span {
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
}
#footer .footer-bottom-container #footer-agreement-bottom {
  display: none;
}

.footer-container {
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 3rem;
}
@media screen and (min-width: 768px) {
  .footer-container {
    width: 75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .footer-container {
    width: 97rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .footer-container {
    width: 129.8rem !important;
  }
}

.overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 99999;
  top: 0;
  left: 0;
}
.overlay-container .overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.overlay-container .overlay.mask {
  background-color: rgba(0, 0, 0, 0.3);
}
.overlay-container .overlay .overlay-item {
  background-color: #fff;
  padding: 1.5rem;
  width: 95vw;
  position: relative;
}
.overlay-container .overlay .overlay-item .overlay-close {
  width: 2.8rem;
  height: 3rem;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.overlay-container .overlay .overlay-item .overlay-close::before {
  content: "P";
  font-family: sonyngp !important;
  font-style: normal !important;
  font-weight: 400 !important;
  -webkit-font-feature-settings: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-size: 2.8rem;
  line-height: 2.8rem;
  cursor: pointer;
}
.overlay-container .overlay .overlay-item .overlay-close:hover {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
@media screen and (min-width: 1200px) {
  .overlay-container.overlay-large .overlay-item {
    width: 56rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .overlay-container.overlay-medium .overlay-item {
    width: 46rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .overlay-container.overlay-small .overlay-item {
    width: 36rem !important;
  }
}
.overlay-container.no-padding .overlay-item {
  padding: 0;
}
.overlay-container.out-close .overlay-close {
  top: -2.5rem !important;
  right: -3.5rem !important;
}
@media screen and (max-width: 768px) {
  .overlay-container.out-close .overlay-close {
    top: -3.5rem !important;
    right: 0rem !important;
  }
}

body.xf-web-container .overlay-container {
  display: block;
}

.h5-video {
  /*  .fds-activity-indicator {
      z-index: 3999;
    }*/
}
.h5-video .desktop,
.h5-video .mobile {
  position: relative;
}
.h5-video .desktop video,
.h5-video .mobile video {
  width: 100%;
}
.h5-video .desktop .voice-btn,
.h5-video .mobile .voice-btn {
  position: absolute;
  top: 48px;
  right: 34px;
  z-index: 999;
  cursor: pointer;
  display: none !important;
}
@media screen and (max-width: 768px) {
  .h5-video .desktop .voice-btn,
  .h5-video .mobile .voice-btn {
    top: 24px;
    right: 16px;
  }
}
.h5-video .desktop .voice-btn .icon,
.h5-video .mobile .voice-btn .icon {
  width: 20px;
  height: 20px;
  display: none;
}
.h5-video .desktop .voice-btn .icon.show,
.h5-video .mobile .voice-btn .icon.show {
  display: block;
}
.h5-video .videoContainer {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .h5-video .mobile {
    display: block;
    position: relative;
  }
}
@media screen and (min-width: 767px) {
  .h5-video .mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .h5-video .desktop {
    display: none;
  }
}
@media screen and (min-width: 767px) {
  .h5-video .desktop {
    display: block;
    position: relative;
  }
}
.h5-video .videoImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.h5-video .videoImg img {
  width: 100%;
}
.h5-video .mobile button {
  -webkit-appearance: none !important;
}
.h5-video .mobile video .media-controls-container {
  display: none !important;
}
.h5-video .mobile video .media-controls {
  display: none !important;
}
.h5-video .mobile video .play-pause {
  display: none !important;
}
.h5-video .h5_video {
  position: relative;
  width: 100%;
}
.h5-video .h5_video .mask {
  background-color: #000000;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 9;
}
.h5-video .h5_video .videoSize {
  height: auto;
  width: 100%;
  display: block;
}
.h5-video .h5_video .controlBar {
  width: 15%;
  height: 50px;
  position: absolute;
  z-index: 11;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.h5-video .h5_video .controlBar .control {
  position: absolute;
  display: block;
  /*bottom: 12px;
  right: 20px;*/
  width: 32px;
  height: 32px;
  /*border: 1px solid #fff;*/
  /*border-radius: 50%;*/
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.h5-video .h5_video .controlBar .control:hover {
  /*background-color: #0276b3;*/
}
.h5-video .h5_video .controlBar .btn-control::before {
  content: "";
  font-size: 16px;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -51%) rotate(180deg);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.h5-video .h5_video .controlBar .playing::before {
  border-style: solid;
  border-width: 20px 50px 20px 0;
  border-color: transparent #fff transparent transparent;
  line-height: 13px;
  text-shadow: none;
  display: block;
  width: 13px;
  height: 13px;
  top: 50%;
  left: 60%;
  cursor: pointer;
}
.h5-video .h5_video .controlBar .paused::before {
  line-height: 32px;
  border: 0;
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  height: 30px;
  top: 50%;
  left: 50%;
  width: 18px;
  cursor: pointer;
}
.h5-video .h5_video .textContainer {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  color: red;
  width: 100%;
  height: 100%;
}
.h5-video .h5_video .textContainer .textCenter {
  margin: 0 auto;
}

/*.billboardbtnvideo{
  position: absolute;
  z-index: 1000;
  top: 10%;
  left: 5%;
  width: 90%;
  @media screen and (max-width: 767px){
    width: 80%;
    top: 10px;
    left: 10%;
  }
  video{
    display: block;
  }
  .video-js{
    width: 100%;
    height: auto;
    @media screen and (max-width: 767px){
      height: 174px;
    }
  }
  .overlay-close{
    position: absolute;
    right: -17px;
    z-index: 1100;
    background-color: gray;
    height: 2rem;
  }
}*/
/*.fds-flex__items--start{
  z-index: 100;
}*/
button.center {
  display: none !important;
  -webkit-appearance: none !important;
}

.hide-float {
  width: 60px;
  position: fixed;
  right: 35px;
  bottom: 110px;
  z-index: 99;
}

.hide-float .side-icon {
  cursor: pointer;
  text-align: center;
  color: #000;
  width: 68px;
  height: 68px;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #DDDDDD;
}

.hide-float .side-icon:last-child {
  border-bottom: none;
}

.hide-float .side-icon > .image {
  width: 24px;
  height: 24px;
  background-position: 0 0;
  background-size: 100% 100%;
  flex-shrink: 0;
}

.hide-float .side-icon > .text {
  margin-top: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D1D1F;
  line-height: 17px;
  flex-shrink: 0;
  /* display: flex; */
  /* flex-direction: column; */
}

.hide-float .side-icon:hover > .text {
  color: #005aff;
}

.hide-float .back-icon.side-icon {
  margin-top: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.hide-float .back-icon.side-icon > .image {
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_top_default.png");
  background-repeat: no-repeat;
  /* background-position: 0 -249px; */
  /* background-position: -6px -261px; */
}

.hide-float .back-icon.side-icon:hover > .image {
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_top_active.png");
  background-repeat: no-repeat;
}

.hide-float .isopen-icon .icon-list {
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.hide-float .survey-icon.side-icon > .image {
  /* background-position: 0 0; */
  /* background-position: -78px -6px; */
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_feedback_default.png");
  background-repeat: no-repeat;
}

.hide-float .survey-icon.side-icon:hover > .image {
  /* background-position: -60px 0; */
  /* background-position: -6px -6px; */
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_feedback_active.png");
  background-repeat: no-repeat;
}

.hide-float .rss-icon.side-icon:hover > .image {
  /* background-position: -60px 0; */
  /* background-position: -6px -6px; */
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_subscribe_active.png");
  background-repeat: no-repeat;
}

.hide-float .rss-icon.side-icon > .image {
  /* background-position: 0 0; */
  /* background-position: -78px -6px; */
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_subscribe_default.png");
  background-repeat: no-repeat;
}

.hide-float .gallery-icon.side-icon > .image {
  /* background-position: 0 -63px; */
  /* background-position: -78px -69px; */
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_afterSales_default.png");
  background-repeat: no-repeat;
}

.hide-float .gallery-icon.side-icon:hover > .image {
  /* background-position: -60px -63px; */
  /* background-position: -6px -69px; */
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_afterSales_active.png");
  background-repeat: no-repeat;
}

.hide-float .chat-icon.side-icon > .image {
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/customer-service-pdp.png");
  background-repeat: no-repeat;
}

.hide-float .chat-icon.side-icon > .text {
  margin-top: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0034fd;
  line-height: 17px;
  flex-shrink: 0;
}

.hide-float .chat-icon.side-icon:hover > .image {
  background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/customer-service-pdp.png");
  background-repeat: no-repeat;
}

.carParameter_flex {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  flex-wrap: wrap;
}

.carParameter_item {
  padding: 10px 16px;
  text-align: center;
  position: relative;
}

.carParameter_item::before {
  position: absolute;
  content: "";
  right: 0px;
  margin-top: -20px;
  top: 50%;
  width: 0;
  height: 40px;
  border-right: 1px solid #e0e0ee;
}

.carParameter_item:first-child {
  padding-left: 0;
}

.carParameter_item:last-child {
  padding-right: 0;
}

.carParameter_item:last-child::before {
  display: none;
}

.carParameter_t1 {
  font-size: 20px;
  color: #000000;
}

.carParameter_t2 {
  margin-top: 10px;
  font-size: 13px;
  color: #000;
}

@media screen and (max-width: 768px) {
  .carParameter_item {
    width: 50%;
    margin-bottom: 15px;
  }
  .carParameter_item:last-child::before {
    display: none;
  }
  .carParameter_item:nth-child(even)::before {
    display: none;
  }
}
.img974 {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

#i1,
#i2 {
  position: absolute;
  top: 0;
  left: 0;
}

.img974-box {
  margin: 10px auto 0 auto;
}

.img974-box h2 {
  color: #2f353d;
  font-size: 2.375em;
  line-height: 1.15789;
  margin-bottom: 24px;
  font-weight: normal;
  letter-spacing: -0.04em;
  text-align: center;
}

.___common_slide_bottom_text {
  padding: 15px 4%;
  background-color: rgba(33, 37, 48, 0.95);
  color: #bcbccc;
  line-height: 1.5;
  bottom: 0;
  font-size: 1em;
}

.img974-box img {
  width: 100%;
  background-size: cover;
}

.ctrl-img974 {
  position: relative;
  height: 132px;
  line-height: 132px;
  color: #83838F;
}

.ctrl-img974 .cir-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ctrl-img974 span.left {
  float: left;
  width: 43%;
  display: inline-block;
  text-align: right;
  line-height: 132px;
}

.ctrl-img974 span.right {
  float: right;
  width: 43%;
  display: inline-block;
  text-align: left;
  display: none;
  line-height: 132px;
}

.ctrl-img974 .ctrl-box {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100px;
  height: 44px;
  margin: 0 30px;
  border: 8px solid #cecdcf;
  border-radius: 22px;
  background-color: #83838F;
  vertical-align: middle;
  cursor: pointer;
}

#cir .cir {
  transition: all 0.4s ease;
}

#cir .cir.active {
  left: 57px;
  transform: rotate(180deg);
}

#cir .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  height: 28px;
  border-radius: 22px;
  background-color: #f25529;
  transition: width 0.4s ease;
}

#cir .bg.active {
  width: 87px;
  border-radius: 22px;
}

.ctrl-img974 .ctrl-box .cir .tri-b {
  position: relative;
}

.ctrl-img974 .ctrl-box .cir {
  box-sizing: content-box;
  position: absolute;
  left: -2px;
  top: -2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid rgba(183, 181, 185, 0.8);
  background-color: #f25529;
  z-index: 33;
}

.ctrl-img974 .ctrl-box .cir .tri-b:after {
  position: absolute;
  top: -2px;
  left: -2px;
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  background: url("image/arrow.png") no-repeat center;
  background-size: 16px;
}

@media (max-width: 48em) {
  .ctrl-img974 span.left,
  .ctrl-img974 span.right {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }
}
.galleryNewSection_box .gallery_img {
  font-size: 0;
  line-height: 0;
}

.galleryNewSection_box .gallery_img_opacity img,
.galleryNewSection_box .gallery_img img {
  width: 100%;
}

.galleryNewSection_box .gallery_img, .galleryNewSection_box .gallery_text {
  display: none;
}

.galleryNewSection_box .gallery_img:first-child, .galleryNewSection_box .gallery_text:first-child {
  display: block;
}

.galleryNewSection_box .tab__switch {
  position: absolute;
  left: 0%;
  bottom: 21px;
  height: 58px;
  line-height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
}

.galleryNewSection_box .tab__tabs {
  display: flex;
  background: #FFFFFF;
  border-radius: 9px;
}

.galleryNewSection_box .tab__nav {
  position: relative;
}

.galleryNewSection_box .tab__nav::before {
  position: absolute;
  content: "";
  left: -20px;
  margin-top: -13px;
  top: 50%;
  width: 0;
  height: 26px;
  border-right: 1px solid #E0E0E0;
}

.galleryNewSection_box .tab__nav:first-child::before {
  display: none;
}

.galleryNewSection_box .tab__nav::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #0A83D7;
  display: none;
}

.galleryNewSection_box .tab__nav.active::after {
  display: block;
}

.galleryNewSection_box .tab__switch .tab__nav {
  margin: 0 20px;
}

.galleryNewSection_box .gallery_text {
  font-size: 2rem;
  color: #333333;
  line-height: 2.7rem;
  padding: 2rem;
}

.galleryNewSection_box .gallery_t1 {
  font-size: 3.4rem;
  font-weight: 600;
  color: #000000;
  line-height: 5rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 1200px) {
  .galleryNewSection_box .gallery_opacity .gallery_pic {
    display: none;
  }
  .galleryNewSection_box .gallery_opacity .container-v2.position > .cmp-container {
    position: static;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
  }
  .ctrl-img974 span.left, .ctrl-img974 span.right {
    line-height: 92px;
  }
  .ctrl-img974 {
    height: 92px;
  }
}
@media screen and (max-width: 480px) {
  .galleryNewSection_box .tab__switch .tab__nav {
    margin: 0 14px;
  }
  .galleryNewSection_box .tab__switch {
    bottom: 8px;
  }
}