.hide-float {
    width: 60px;
    position: fixed;
    right: 35px;
    bottom: 110px;
    z-index: 99;
}

.hide-float .side-icon {
    cursor: pointer;
    text-align: center;
    color: #000;
    width: 68px;
    height: 68px;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DDDDDD;;
}
.hide-float .side-icon:last-child{
    border-bottom: none;
}
.hide-float .side-icon>.image {
    width: 24px;
    height: 24px;
    background-position: 0 0;
    background-size: 100% 100%;
    flex-shrink: 0;
}
.hide-float .side-icon>.text{
    margin-top: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D1D1F;
    line-height: 17px;
    flex-shrink: 0;

    /* display: flex; */
    /* flex-direction: column; */
}
.hide-float .side-icon:hover>.text{
    color: #005aff;
}


.hide-float .back-icon.side-icon{
    margin-top: 12px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    overflow: hidden;
}
.hide-float .back-icon.side-icon > .image {
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_top_default.png");
    background-repeat: no-repeat;
    /* background-position: 0 -249px; */
    /* background-position: -6px -261px; */
}
.hide-float .back-icon.side-icon:hover > .image {
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_top_active.png");
    background-repeat: no-repeat;
}

.hide-float .isopen-icon .icon-list {
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}

.hide-float .survey-icon.side-icon > .image{
    /* background-position: 0 0; */
    /* background-position: -78px -6px; */
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_feedback_default.png");
    background-repeat: no-repeat;
}

.hide-float .survey-icon.side-icon:hover > .image{
    /* background-position: -60px 0; */
    /* background-position: -6px -6px; */
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_feedback_active.png");
    background-repeat: no-repeat;
}

.hide-float .rss-icon.side-icon:hover > .image {
    /* background-position: -60px 0; */
    /* background-position: -6px -6px; */
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_subscribe_active.png");
    background-repeat: no-repeat;
}

.hide-float .rss-icon.side-icon > .image {
    /* background-position: 0 0; */
    /* background-position: -78px -6px; */
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_subscribe_default.png");
    background-repeat: no-repeat;
}

.hide-float .gallery-icon.side-icon > .image {
    /* background-position: 0 -63px; */
    /* background-position: -78px -69px; */
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_afterSales_default.png");
    background-repeat: no-repeat;
}

.hide-float .gallery-icon.side-icon:hover > .image{
    /* background-position: -60px -63px; */
    /* background-position: -6px -69px; */
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/icon_survey_afterSales_active.png");
    background-repeat: no-repeat;
}

.hide-float .chat-icon.side-icon > .image {
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/customer-service-pdp.png");
    background-repeat: no-repeat;
}
.hide-float .chat-icon.side-icon>.text{
    margin-top: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0034fd;
    line-height: 17px;
    flex-shrink: 0;
}

.hide-float .chat-icon.side-icon:hover > .image{
    background-image: url("/etc.clientlibs/professional/clientlibs/clientlib-site/resources/images/icon/customer-service-pdp.png");
    background-repeat: no-repeat;
}