.container-v2{
  .image{
    .cmp-image{
      img{
        display: block;
      }
    }
  }
}

.cmp-image {}
.cmp-image__link {}
.cmp-image__image {width:100%;}
.cmp-image__title {}
