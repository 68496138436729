.carParameter_flex {
  display: inline-flex;
  align-items: center;
  // white-space: nowrap;
  line-height: 1;
  flex-wrap: wrap;
}

.carParameter_item {
  padding: 10px 16px;
  text-align: center;
  position: relative;
}

.carParameter_item::before {
  position: absolute;
  content: '';
  right: 0px;
  margin-top: -20px;
  top: 50%;
  width: 0;
  height: 40px;
  border-right: 1px solid #e0e0ee;
}


.carParameter_item:first-child {
  padding-left: 0;
}

.carParameter_item:last-child {
  padding-right: 0;
}

.carParameter_item:last-child::before {
  display: none;
}


.carParameter_t1 {
  font-size: 20px;
  color: #000000;
}

.carParameter_t2 {
  margin-top: 10px;
  font-size: 13px;
  color: #000;
}



@media screen and (max-width: 768px) {

  .carParameter_item {
    width: 50%;
    margin-bottom: 15px;
  }

  .carParameter_item:last-child::before {
    display: none;
  }

  .carParameter_item:nth-child(even)::before {
    display: none;
  }
  
}








.img974 {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

#i1,
#i2 {
  position: absolute;
  top: 0;
  left: 0;
}

.img974-box {
  margin: 10px auto 0 auto;
}

.img974-box h2 {
  color: #2f353d;
  font-size: 2.375em;
  line-height: 1.15789;
  margin-bottom: 24px;
  font-weight: normal;
  letter-spacing: -0.04em;
  text-align: center;
}

.img974-box .img974 {
  
}

.___common_slide_bottom_text{
  padding:15px 4%;background-color: rgba(33, 37, 48, 0.95);color: #bcbccc;line-height: 1.5;bottom:0;font-size:1.0em;
}

.img974-box img {
  width: 100%;
  background-size: cover;
}

.ctrl-img974 {
  position: relative;
  height: 132px;
  line-height: 132px;
  color: #83838F;
}

.ctrl-img974 .cir-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.ctrl-img974 span.left {
  float: left;
  width: 43%;
  display: inline-block;
  text-align: right;
  line-height: 132px;
}

.ctrl-img974 span.right {
  float: right;
  width: 43%;
  display: inline-block;
  text-align: left;
  display: none;
  line-height: 132px;
}

.ctrl-img974 .ctrl-box {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100px;
  height: 44px;
  margin: 0 30px;
  border: 8px solid #cecdcf;
  border-radius: 22px;
  background-color: #83838F;
  vertical-align: middle;
  cursor: pointer;
}

#cir .cir {
  transition: all 0.4s ease;
}

#cir .cir.active {
  left: 57px;
  transform: rotate(180deg);
}

#cir .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  height: 28px;
  border-radius: 22px;
  background-color: #f25529;
  transition: width .4s ease;
}

#cir .bg.active {
  width: 87px;
  border-radius: 22px;
}

.ctrl-img974 .ctrl-box .cir .tri-b {
  position: relative;
}


.ctrl-img974 .ctrl-box .cir {
  box-sizing: content-box;
  position: absolute;
  left: -2px;
  top: -2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid rgba(183, 181, 185, .8);
  background-color: #f25529;
  z-index: 33;
}

.ctrl-img974 .ctrl-box .cir .tri-b:after {
  position: absolute;
  top: -2px;
  left: -2px;
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  background: url("image/arrow.png") no-repeat center;
  background-size: 16px;
}


@media (max-width: 48em) {
  .ctrl-img974 span.left,
  .ctrl-img974 span.right {
      width: 100%;
      margin-top: 50px;
      text-align: center;
  }
}






.galleryNewSection_box{}    

.galleryNewSection_box .gallery_img{ font-size: 0; line-height: 0;}
.galleryNewSection_box .gallery_img_opacity img,
.galleryNewSection_box .gallery_img img{
    width: 100%;
}
.galleryNewSection_box .gallery_img,.galleryNewSection_box .gallery_text{
  display: none;
}
.galleryNewSection_box .gallery_img:first-child,.galleryNewSection_box .gallery_text:first-child{
  display: block;
}



.galleryNewSection_box .tab__switch {
    position: absolute;
    left: 0%;
    bottom: 21px;
    height: 58px;
    line-height: 53px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
}
.galleryNewSection_box .tab__tabs{
    display: flex;
     background: #FFFFFF;
    border-radius: 9px;   
}

.galleryNewSection_box .tab__nav{
    position: relative;
}

.galleryNewSection_box .tab__nav::before{
    position: absolute;
    content: '';
    left: -20px;
    margin-top: -13px;
    top: 50%;
    width: 0;
    height: 26px;
    border-right: 1px solid #E0E0E0;
}



.galleryNewSection_box .tab__nav:first-child::before{
    display: none;
}



.galleryNewSection_box .tab__nav::after{
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: #0A83D7;
    display: none;
}

.galleryNewSection_box .tab__nav.active::after{
    display: block;
}

.galleryNewSection_box .tab__switch .tab__nav{
    margin: 0 20px;
}

.galleryNewSection_box .gallery_text{
    font-size: 2rem;
    color: #333333;
    line-height: 2.7rem;
    padding: 2rem;
        
}
.galleryNewSection_box .gallery_t1{
    font-size: 3.4rem;
     font-weight: 600;
    color: #000000;
    line-height: 5rem;
    padding-bottom: 1rem;
}



@media screen and (max-width: 1200px) {
    .galleryNewSection_box .gallery_opacity .gallery_pic{
        display: none;
    }
    .galleryNewSection_box .gallery_opacity .container-v2.position>.cmp-container{
        position: static;
        left: 0;
        top: 0;
        transform:translate(0%, 0%)

    }
    .ctrl-img974 span.left,.ctrl-img974 span.right{ line-height: 92px;}
    .ctrl-img974{ height: 92px;}
    
}

@media screen and (max-width: 480px) {
  .galleryNewSection_box .tab__switch{
    // font-size: 11px;
  }
  .galleryNewSection_box .tab__switch .tab__nav{
    margin:0 14px;
  }
  .galleryNewSection_box .tab__switch{
    bottom: 8px;
  }
}

