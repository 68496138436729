.root{
  .cmp-container{
    main{
      overflow: hidden;
    }
  }
}

h2.cmp-title__text{
  font-size: 2.8rem;
  line-height: 3.2rem;
  text-align: center;
  display: block;
  margin-bottom:1.5rem;
  font-weight:500;
  padding-top:6rem;
  @media screen and (max-width: 425px) {
    padding-top:3rem;
  }
}
.solutions-container{
  margin-right: auto !important;
  margin-left: auto !important;
  @media screen and (min-width: 768px) {
    width: 75rem !important;
  }
  @media screen and (min-width: 992px) {
    width: 97rem !important;
  }
  @media screen and (min-width: 1200px) {
    width: 117rem !important;
  }
  .solutionitem{
    width:100%;
    height: 21rem;
    margin-bottom: .1rem;
    padding-left: .1rem;
    padding-right: .1rem;
    float:left;
    @media screen and (min-width: 425px) {
      width: 50% !important;
      box-sizing: border-box;
    }
    @media screen and (min-width: 768px) {
      width: 50% !important;
    }
    @media screen and (min-width: 992px) {
      width: 32.1rem !important;
    }
    @media screen and (min-width: 1200px) {
      width: 38.7rem !important;
    }
    a{
      background-color: #2c3e50;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width:100%;
      height: 100%;
      position: relative;
      .title{
        box-sizing: border-box;
        position: absolute;
        bottom:0;
        background-color: rgba(44,62,80,.65)!important;
        color: #fff;
        left: 0;
        padding: 15px;
        position: absolute;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        width: 100%;
        z-index: 2;
        h3{
          color: #fff;
          line-height: 24px;
          margin-bottom: 0;
          font-weight: 500;
          display: inline-block;
        }
        p{
          display: inline-block;
          margin:0;
        }
      }
      &:hover{
        &::after{
          opacity: 0.25;
        }
      }
      &::after{
        background-color: #000;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        width: 100%;
        opacity: 0;
      }
    }
  }
}

